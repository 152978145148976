import {TPaintProcessValue} from '../../services/project-types'
import {TFactoryCorniceStyle} from './cornice'
import {TFactoryDoorDirection, TFactoryHingeType} from './door'
import {IUnitPrice} from '../../common/interface/product-types'
import {DefaultMap} from '../../application/helpers'
import {
  LUCKA_DJUPADAL,
  LUCKA_HOGALID,
  LUCKA_MALMGARDEN,
  LUCKA_MELLANHEDEN,
  LUCKA_RONNEHOLM,
  LUCKA_SORGENFRI,
  LUCKA_SUNNANA
} from '../../model/model-types'
import {TFactorySocleStyle} from './socle-board'

export interface IPaintProcessCosts {
  labor: number
  price: number
  material: number
  cReduction: number
  fReduction: number
}

export const paintPrices = new DefaultMap<TPaintProcessValue | 0, IPaintProcessCosts>([
  [1, {labor: 40, price: 27, material: 115, cReduction: 0, fReduction: 4}], // Standard linseed paint process, finalized in carpentry
  [2, {labor: 30, price: 27, material: 75, cReduction: 0, fReduction: 0}], // Standard linseed paint process
  [3, {labor: 20, price: 27, material: 115, cReduction: 5, fReduction: 0}], // Primered, linseed paint process
  [4, {labor: 0, price: 27, material: 0, cReduction: 12, fReduction: 12}], // Unpainted
  [5, {labor: 30, price: 27, material: 115, cReduction: 0, fReduction: 4}], // Regular paint - NOT LINSEED PAINT - finalized in carpentry
  [6, {labor: 20, price: 27, material: 75, cReduction: 5, fReduction: 0}], // Primer, Regular paint - NOT LINSEED PAINT
  [7, {labor: 0, price: 0, material: 0, cReduction: 0, fReduction: 0}], //  "4. Omålat på utsidorna, vaxat på insidorna" NO_PAINT_WAXED_INSIDE
  [8, {labor: 0, price: 0, material: 0, cReduction: 0, fReduction: 0}] // "8. Vanlig färg, slutmålat i snickeriet" ORDINARY_FACTORY_COMPLETE
], [0, {labor: 0, price: 0, material: 0, cReduction: 0, fReduction: 0}])

export const doorPrices = new DefaultMap<TFactoryDoorStyle, IUnitPrice>([
  [LUCKA_HOGALID, {price: 27, labor: 144}],
  [LUCKA_DJUPADAL, {price: 27, labor: 132}],
  [LUCKA_RONNEHOLM, {price: 27, labor: 115}],
  [LUCKA_SUNNANA, {price: 27, labor: 132}],
  [LUCKA_MALMGARDEN, {price: 27, labor: 144}],
  [LUCKA_SORGENFRI, {price: 27, labor: 115}],
  [LUCKA_MELLANHEDEN, {price: 27, labor: 144}]
], [])

export const frontTypeCost: DefaultMap<TFactoryFrontStyle, number> = new DefaultMap<TFactoryFrontStyle, number>([
  ['Släta lådfronter', 0],
  ['Standard', 13]
], ['Släta lådfronter', 0])

export const RequiredControls = new Map<TFactoryExtraType, string[]>([
  ['door', ['doorStyle', 'height', 'width', 'paintProcess']],
  ['drawerFront', ['height', 'width', 'drawerBoxDepth', 'paintProcess', 'doorStyle']],
  ['drawerFrontNoDrawerBox', ['height', 'width', 'paintProcess', 'doorStyle']],
  ['drawerBox', ['drawerBoxWidth', 'drawerBoxHeight', 'drawerBoxDepth']],
  ['cornice', ['paintProcess']],
  ['socle', ['paintProcess']],
  ['plateRack', ['paintProcess']],
  ['vGroove', ['paintProcess', 'height', 'width']],
  ['other', ['paintProcess', 'width', 'height', 'depth', 'image']],
  ['board', ['paintProcess', 'height', 'width', 'depth']],
])

export const FactoryExtraTypes = ['cornice', 'drawerFront', 'drawerFrontNoDrawerBox', 'drawerBox', 'door', 'socle', 'plateRack', 'vGroove', 'other', 'board'] as const
export type TFactoryExtraType = typeof FactoryExtraTypes[number]

export const FactoryExtraUnits = ['mm', 'm', 'pcs', 'm2'] as const
export type TFactoryExtraUnit = typeof FactoryExtraUnits[number]

export const FactoryFrontStyle = ['Standard', 'Släta lådfronter'] as const
export type TFactoryFrontStyle = typeof FactoryFrontStyle[number]

export const FactoryDoorStyles = [LUCKA_HOGALID, LUCKA_DJUPADAL, LUCKA_RONNEHOLM, LUCKA_SUNNANA, LUCKA_MALMGARDEN, LUCKA_SORGENFRI, LUCKA_MELLANHEDEN] as const
export type TFactoryDoorStyle = typeof FactoryDoorStyles[number]

export const CUSTOMER_PRICE_FACTOR = 27
export const CUSTOMER_MATERIAL_FACTOR = 2.2

export const FactoryExtraWoodTypes = ['pine', 'ash', 'oak'] as const
export type TFactoryExtraWoodTypes = typeof FactoryExtraWoodTypes[number]

export interface IFactoryExtra {
  /**
   * A unique id for this _INSTANCE_ of extra
   */
  id: string

  /**
   * Future use if we put these in DB, should always be 'FE'
   */
  type: string

  /**
   * In case we need it.
   */
  version: number

  /**
   * "type" is mandatory ... what is default? We reserve
   * the word "type" for the database use and we call this
   * extrasType
   */
  extrasType: TFactoryExtraType

  /**
   * Price is price to customer if any. Should be 0 if none
   * and always in SEK (kr)
   */
  price: number

  /**
   * Labor is price to factory, we use "labor" for legacy reasons.
   * Price in € and always 0 or more
   */
  labor: number

  /**
   * Quantity, always a minimum of 1
   */
  quantity: number

  /**
   * Paint process must always have a value?
   * If not we set it to 0. Most factory
   * extras have it?
   */
  paintProcess: TPaintProcessValue | 0

  /**
   * We measure in meters, sq. m or pcs or what ever.
   * We use it for display only.
   */
  unit: TFactoryExtraUnit

  /**
   * An image of a drawing. Used in socle and cornice
   */
  image: string

}

/**
 * This is a fake super type to be able to
 * pass a generic specialized Extra!! (wow!)
 */
export type TFactoryAll = Partial<FactoryExtra>

export class FactoryExtra implements IFactoryExtra {

  /**
   * Generate an UUID.
   */
  public id = self.crypto.randomUUID()

  /**
   * Must always be a string
   */
  public type = 'FE'

  /**
   * Basically future use
   */
  public version: number = 0

  public height: number = null

  public width: number = null

  public depth: number = null

  /**
   * Let us make cornice the default. I do not
   * want this to be "nullable". Potentially we can
   * have an undefined type or something.
   */
  public extrasType: TFactoryExtraType = 'cornice'

  public paintProcess: TPaintProcessValue = null

  public color: string = ''

  public descriptionForFactory: string = ''

  public descriptionForCustomer: string = ''

  public hingeType: TFactoryHingeType = null

  public doorStyle: TFactoryDoorStyle = null

  public doorDirection: TFactoryDoorDirection = null

  public midPanelCount = 0

  /**
   * Drawer front
   */
  public drawerFrontType: TFactoryFrontStyle = null
  public waxedInside: boolean = null
  public drawerRunners: boolean = null
  public cutoutHandle: boolean = null
  public drawerBoxHeight: number = null
  public drawerBoxDepth: number = null
  public drawerBoxWidth: number = null

  /**
   * Cornice
   */
  public corniceStyle: TFactoryCorniceStyle

  /**
   * Socle
   */
  public socleStyle: TFactorySocleStyle

  public image: string = null
  /**
   * The below has to be set by the subclasses as default
   * values.
   */
  public price: number = 0
  public labor: number = 0
  public material: number = 0
  public quantity: number = 1
  public unit: TFactoryExtraUnit = 'pcs'

  /**
   * Plate rack
   */
  public plateRackDimension = null

  /**
   * Board
   */
  public woodType: TFactoryExtraWoodTypes = null

  /**
   * Override price data finally and forever.
   */
  public setPrice: number | null = null
  public setLabor: number | null = null

  constructor(input: Partial<IFactoryExtra> = {}) {
    Object.assign(this, input)
  }

  get totalFactoryPrice(): number {
    if (this.setLabor !== null) {
      return this.setLabor
    }
    return this.labor * this.quantity
  }

  get totalCustomerPrice(): number {
    if (this.setPrice !== null) {
      return this.setPrice
    }
    return this.price * this.quantity
  }

  get totalMaterialPrice(): number {
    return this.material * this.quantity
  }

  get totalVolume(): number | null {
    return null
  }

  get totalHeight(): number {
    return 0
  }
}
