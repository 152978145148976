import {Injectable, InjectionToken} from '@angular/core'
import {Observable, ReplaySubject} from 'rxjs'
import { HttpClient } from '@angular/common/http'
import {environment} from '../../../environments/environment'
import {map} from 'rxjs/operators'
import {Appliance, IAppliance} from '../model/appliance'
import {TApplianceItemKey} from '../model/appliance-map'

export type TApplianceTypeName =
  'missing'
  | 'stove'
  | 'hob'
  | 'hobWExt'
  | 'stoveTop'
  | 'oven'
  | 'fan'
  | 'dishwasher'
  | 'refrigerator'
  | 'wineFridge'
  | 'freezer'
  | 'fridgeFreezer'
  | 'micro'
  | 'handle'
  | 'knobs'
  | 'knob'
  | 'faucet'
  | 'sink'
  | 'basketStrainer'
  | 'sinkBench'
  | 'factoryExtras'
  | 'linseedPaint'
  | 'other'
  // Group is a special item that we cannot use.
  | 'group'

export interface ApplianceType {
  name: TApplianceTypeName
  displayName: string
  displayNameEn: string
}

export const applianceTypes: ApplianceType[] = [
  {
    name: 'missing',
    displayName: 'Missing',
    displayNameEn: 'Missing'
  },
  {
    name: 'stove',
    displayName: 'Spis',
    displayNameEn: 'Stove'
  },
  {
    name: 'hob',
    displayName: 'Häll',
    displayNameEn: 'Hob'
  },
  {
    name: 'hobWExt',
    displayName: 'Häll med fläkt',
    displayNameEn: 'Hob with extractor'
  },
  {
    name: 'stoveTop',
    displayName: 'Häll',
    displayNameEn: 'Hob'
  },
  {
    name: 'oven',
    displayName: 'Ugn',
    displayNameEn: 'Oven'
  },
  {
    name: 'fan',
    displayName: 'Fläkt',
    displayNameEn: 'Extractor'
  },
  {
    name: 'dishwasher',
    displayName: 'Diskmaskin',
    displayNameEn: 'Dishwasher'
  },
  {
    name: 'refrigerator',
    displayName: 'Kylskåp',
    displayNameEn: 'Fridge'
  },
  {
    name: 'wineFridge',
    displayName: 'Vinkyl',
    displayNameEn: 'Wine fridge'
  },
  {
    name: 'freezer',
    displayName: 'Frys',
    displayNameEn: 'Freezer'
  },
  {
    name: 'fridgeFreezer',
    displayName: 'Kombinerad kyl/frys',
    displayNameEn: 'Combo fridge/freezer'
  },
  {
    name: 'micro',
    displayName: 'Micro',
    displayNameEn: 'Microwave'
  },
  {
    name: 'handle', displayName: 'Handtag',
    displayNameEn: 'Handles'
  },
  {
    name: 'knobs', displayName: 'Knoppar',
    displayNameEn: 'Knobs'
  },
  {
    name: 'knob', displayName: 'Vred',
    displayNameEn: 'Turning knobs'
  },
  {
    name: 'faucet', displayName: 'Blandare',
    displayNameEn: 'Faucet'
  },
  {
    name: 'sink', displayName: 'Diskho',
    displayNameEn: 'Sink'
  },
  {
    name: 'basketStrainer', displayName: 'Avloppsventil',
    displayNameEn: 'Basket strainer'
  },
  {
    name: 'sinkBench', displayName: 'Diskbänk',
    displayNameEn: 'Stainless steel countertop'
  },
  {
    name: 'factoryExtras', displayName: 'Extra från snickeri',
    displayNameEn: 'Carpentry  extras'
  },
  {
    name: 'linseedPaint', displayName: 'Linoljefärg',
    displayNameEn: 'Linseed paint'
  },
  {
    name: 'other', displayName: 'Övrigt',
    displayNameEn: 'Other'
  }
]

export interface IApplianceService {
  /**
   * A function that saves the appliance
   *
   * @param appliance - The appliance to save
   * @returns - The same appliance potentially updated
   */
  saveAppliance(appliance: Appliance): Observable<Appliance>

  /**
   * A function that deletes the appliance
   *
   * @param appliance - The appliance to deletes
   */
  deleteAppliance(appliance: Appliance): Observable<void>
}

export const APPLIANCE_SERVICE = new InjectionToken<IApplianceService>('applianceService')

@Injectable({
  providedIn: 'root'
})
export class ApplianceService implements IApplianceService {

  public appliances$: ReplaySubject<Appliance[]> = new ReplaySubject<Appliance[]>()

  private appliances: Appliance[] = []

  constructor(
    private httpClient: HttpClient
  ) {
    const url = `${environment.productUrl}/appliances`
    this.httpClient.get<IAppliance[]>(url).subscribe({
      next: (appliances: Appliance[]) => {
        this.appliances = appliances.map((a: IAppliance) => new Appliance(a))
        // Silly conversion for development, remove when new
        // appliances are in place.
        this.appliances.forEach(a => a.controls())
        this.appliances$.next(this.appliances)
      }
    })
  }

  /**
   * Create and delete are server operations
   */
  public saveAppliance(appliance: Appliance): Observable<Appliance> {
    let url = `${environment.productUrl}/appliances`
    if (appliance.id) {
      url += `/${appliance.id}`
    }
    return this.httpClient.put<Appliance>(url, appliance).pipe(
      map((updated: Appliance) => {
        const updatedAppliance = new Appliance(updated)
        if (appliance.id) {
          const a1 = this.appliances.find((a: Appliance) => a.id === appliance.id)
          Object.assign(a1, updatedAppliance)
        } else {
          this.appliances.push(updatedAppliance)
        }
        this.appliances$.next(this.appliances)
        return updatedAppliance
      })
    )
  }

  public deleteAppliance(appliance: Pick<Appliance, 'id'>): Observable<any> {
    const url = `${environment.productUrl}/appliances/${appliance.id}`
    return this.httpClient.delete(url).pipe(
      map(() => {
        this.appliances = this.appliances.filter((a: Appliance) => a.id !== appliance.id)
        this.appliances$.next(this.appliances)
      })
    )
  }

  /**
   * Filters a list of appliances based on the path.
   * @param path - We do not modify it
   * @param appliances - You will get a copy.
   */
  public filterByPath(path: TApplianceItemKey[], appliances: Appliance[]): Appliance[] {
    if (path.length === 0) {
      return [...appliances]
    }
    const search = [...path]
    const first = search.shift()
    let filtered = appliances.filter(a => a.applianceTree[0] === first)

    search.forEach((s, i) => {
      filtered = filtered.filter(a => a.applianceTree[i + 1] === s)
    })
    return filtered
  }
}
