import {formatNumber} from '@angular/common'
import {
  Comment,
  CommentHomeEntity,
  Comments
} from '../../comments/model/comment'
import {TEMP_SOURCE_ID} from '../../images/model/project-image'
import {ISettingsConfigSupplier} from '../../services/settings-item.service'
import {
  ApplianceType,
  applianceTypes,
  TApplianceTypeName
} from '../service/appliance.service'
import {
  applianceTree,
  Control,
  IApplianceItem,
  TApplianceItemKey
} from './appliance-map'

const ApplianceTypeMap = new Map<TApplianceItemKey, string>([
  ['white_goods', 'Vitvaror'],
  ['stoves', 'Spis'],
  ['electric', 'Elektrisk'],
  ['gas', 'Gas'],
  ['hobs', 'Spishäll'],
  ['extractorHobs', 'Spishäll med fläkt'],
  ['ovens', 'Ugn'],
  ['microwaveCombos', 'Ugn/mikro-kombination'],
  ['micros', 'Mikrovågsugn'],
  ['free_standing', 'Fristående'],
  ['integrated', 'Integrerad'],
  ['extractors', 'Fläkt'],
  ['fixed', 'Ej utdragbar'],
  ['pull_out', 'Utdragbar'],
  ['wall_mounted', 'Vägghängd'],
  ['free_hanging', 'Frihängande'],
  ['dishwashers', 'Diskmaskin'],
  ['width_60', 'Bredd: 600 mm'],
  ['height_standard', 'Standardhöjd'],
  ['height_xxl', 'Extra hög (XXL)'],
  ['other', 'Icke-standard-höjd'],
  ['width_45', 'Bredd: 450 mm'],
  ['sliding_door', 'Glidskenor'],
  ['door_on_door', 'Door-on-Door'],
  ['fridge', 'Kylskåp'],
  ['width_70', 'Bredd: 700 mm'],
  ['width_90', 'Bredd: 900 mm'],
  ['width_100', 'Bredd: 1000 mm'],
  ['width_120', 'Bredd: 1200 mm'],
  ['wine_fridge', 'Vinkyl'],
  ['freezers', 'Frysskåp'],
  ['fridge_freezers', 'Kyl/frysskåp'],
  ['fittings', 'Beslag'],
  ['handles', 'Handtag'],
  ['brass', 'Mässing'],
  ['chrome_nickel', 'Förnicklad / Krom'],
  ['copper', 'Koppar'],
  ['stainless', 'Rostfritt'],
  ['antique', 'Antik'],
  ['wood', 'Trä'],
  ['leather', 'Läder'],
  ['black', 'Svart'],
  ['white', 'Vitvaror'],
  ['knobs', 'Knoppar'],
  ['turning_knobs', 'Skåpsvred'],
  ['knob', 'Vred'],
  ['large', 'Stor'],
  ['chrome', 'Förnicklad / Krom'],
  ['small', 'Liten'],
  ['hooks', 'Hake'],
  ['knob_hook', 'Vred & hake'],
  ['sink_faucet', 'Diskho & blandare'],
  ['sink', 'Diskho'],
  ['single', 'Enkel'],
  ['double', 'Dubbel'],
  ['porcelain', 'Porslin'],
  ['sink_inset', 'Infälld'],
  ['sink_outside', 'Utanpåliggande'],
  ['faucet', 'Blandare'],
  ['basket_strainer', 'Avloppskorg'],
  ['gold', 'Guldfärgad'],
  ['antique_bronze', 'Antik brons'],
  ['brushed_nickel', 'Borstad nickel'],
  ['paints', 'Färg'],
  ['linseed', 'Linoljefärg'],
  ['standard', 'Kulladal standardkulör'],
  ['1', '1'],
  ['12', '12'],
  ['19', '19'],
  ['25', '25'],
  ['31', '31'],
  ['32', '32'],
  ['34', '34'],
  ['38', '38'],
  ['48', '48'],
  ['58', '58'],
  ['67', '67'],
  ['69', '69'],
  ['86', '86'],
  ['87', '87'],
  ['97', '97'],
  ['100', '100'],
  ['101', '101'],
  ['107', '107'],
  ['111', '111'],
  ['116', '116'],
  ['148', '148'],
  ['151', '151']
])

export interface ISupplier extends ISettingsConfigSupplier {
  /**
   * Nothing new to add, but easier name when applying in non-settings things.
   */
}

export interface IAppliance {
  /**
   * Unique Id for server storage purposes
   */
  id?: string

  /**
   * Version set on server side
   */
  version: number

  /**
   * The type is from the backends and is always 'A'
   */
  type: 'A'

  /**
   * Appliance type is one of:
   */
  applianceType: TApplianceTypeName

  applianceTypeName: string

  /**
   * To keep track of where in the appliance tree the appliance belong
   */
  applianceTree: string[]

  name: string
  nameEn: string

  /**
   * Price is customer price
   */
  price: number

  /**
   * Last time the price was updated
   */
  priceTimeStamp: number

  /**
   * The price to the factory/carpentry
   */
  labor: number

  /**
   * Discount in percent as in 10 (%)
   */
  discount: number

  /**
   * The number of these, only added when used.
   */
  quantity: number

  /**
   * Customer facing description
   */
  description?: string

  /**
   * Factory description, if needed and wanted.
   */
  factoryDescription?: string

  /**
   * Any URL that will showcase this on the internet
   */
  url?: string

  /**
   * An ID of an image previously uploaded
   */
  image?: string

  /**
   * The supplier of the goods.
   */
  supplier: ISupplier

  /**
   * The brand of the appliance
   */
  brand: string

  /**
   * The appliance article number
   */
  artNr: string

  /**
   * Appliance depth
   */
  depth: number

  /**
   * Appliance dishwasher valve
   */
  dishwasherValve: string

  /**
   * Appliance height
   */
  height: number

  /**
   * Appliance size
   */
  size: number

  /**
   * Appliance width
   */
  width: number

  /**
   * Comments if any
   */
  comments: Comment[]
}

export interface IApplianceListItem {
  /**
   * Retain the id since it is used
   */
  id: string
  image: string
  brand: string
  name: string
  price: number
  priceTimeStamp: number
  supplier: string
  nameEn: string
  description: string
  artNr: string
  depth: number
  dishwasherValve: string
  height: number
  size: number
  url: string
  width: number
  applianceTypeName: string
  quantity: number
  discount: number
  totalPrice: number
  comments: Comment[]
}

export class Appliance implements IAppliance, Comments {

  public id = ''
  public version = 0
  public type: 'A'
  public applianceType: TApplianceTypeName = 'stove'
  public applianceTypeName = 'stove'
  public applianceTree: TApplianceItemKey[] = []
  public name = ''
  public nameEn = ''
  public price = 0
  public priceTimeStamp = 0
  /* TODO - This can be removed once all legacy projects with
          appliances as FactoryExtras are gone */
  public labor = 0
  public discount = 0
  public quantity = 1
  public description?: string = ''
  public factoryDescription?: string = ''
  public url?: string = ''
  public image?: string = ''
  public supplier: ISupplier =
    {name: 'Okänd', email: 'mail@exampler.com', type: 'other', discount: 0}
  public brand: string = ''
  public artNr: string = ''
  public dishwasherValve: 'yes' | 'no' = 'no'

  public depth: number = null
  public height: number = null
  public width: number = null

  public size: number = null

  /**
   * Required to support the comments interface
   */
  public commentHome: CommentHomeEntity = {
    type: 'APPLIANCE',
    id: TEMP_SOURCE_ID
  }

  public comments: Comment[] = []

  constructor(appliance: Partial<IAppliance> = {}) {
    Object.assign(this, appliance)
    this.commentHome.id = this.id
    this.resolveType()
  }

  get isLegacy(): boolean {
    return this.applianceTree.length === 0
  }

  get discountAmount(): number {
    return this.price * this.quantity * this.discount / 100
  }

  get handlePresentation(): string {
    let price = formatNumber(this.price, 'fr', '1.0-0')
    const totalPrice = formatNumber(this.price * this.quantity, 'fr', '1.0-0')
    let presString: string
    if (this.isLegacy) {
      presString = `${this.applianceTypeName}: ${this.brand ?? ''} - ${this.name ?? ''}: ${this.quantity} st à ${price} kr = ${totalPrice} kr`
      presString = presString.replace(':  - ', ' - ')
        .replace(' - : ', ': ')
      if (this.discount > 0) {
        price = formatNumber(this.price * this.quantity - this.discountAmount, 'fr', '1.0-0')
        presString = `${presString} - ${this.discount}% rabatt = ${price} kr`
      }
    } else {
      const types = this.translateTypes()
      types.shift() // The very top level is redundant to title.
      presString = `${types.shift()} (${types.join(', ')}) ${this.brand ?? ''} ${this.name ?? ''}: ${this.quantity} st à ${price} kr`
    }
    return presString
  }

  get baseType(): string {
    return this.translateTypes()[1]
  }

  get subTypes(): string {
    const types = this.translateTypes()
    types.splice(0, 2)
    return types.join(', ')
  }

  get priceWoComments(): number {
    let price: number = this.price * this.quantity
    if (this.discount > 0) {
      price = price - (price * this.discount / 100)
    }
    return price
  }

  get commentPrice(): number {
    let price = 0
    this.comments.forEach((comment: Comment) => {
      price += comment.price
    })
    return price
  }

  get totalPrice(): number {
    return this.priceWoComments + this.commentPrice
  }

  get englishPresentation(): string {
    return this.handlePresentation
      .replace(/ rabatt /g, ' discount ')
      .replace(/ st /g, ' pcs ')
      .replace(/^.*:/, `${this.nameEn} -`)
  }

  get factoryInvoice(): string {
    return `${this.factoryDescription}, ${this.quantity} * ${this.labor} € = ${this.quantity * (this.labor)} €`
  }

  public toList(): IApplianceListItem {
    return {
      id: this.id,
      brand: this.brand,
      image: this.image,
      name: this.name,
      price: this.price,
      priceTimeStamp: this.priceTimeStamp,
      supplier: this.supplier.name,
      nameEn: this.nameEn, // type
      description: this.description,
      artNr: this.artNr,
      depth: this.depth,
      dishwasherValve: this.dishwasherValve,
      height: this.height,
      size: this.size,
      url: this.url,
      width: this.width,
      applianceTypeName: this.applianceTypeName,
      quantity: this.quantity,
      discount: this.discount,
      totalPrice: this.totalPrice,
      comments: this.comments
    }
  }


  /**
   * Returns the controls that belong to this appliance,
   *
   * It also sets the best "type" as in appliance type.
   *
   * Note that this is initially implicitly tested by
   * other functions. See Form
   */
  public controls(): Control[] {
    let controls: Control[] = []
    let strings: TApplianceItemKey[] = [...this.applianceTree]


    // Begin with top level
    let start = applianceTree.find(i => i.key === strings[0])
    // This is a conversion that must be removed when we are done
    if (!start) {
      strings = this.translatePath(strings)
      start = applianceTree.find(i => i.key === strings[0])
    }
    strings.shift()
    strings
      .forEach((child: string, index: number) => {
        let item: IApplianceItem = start.options.find((c) => c.key === child)
        if (!item) {
          item = {type: 'missing', controls: []} as any
          strings.splice(index)
        }

        // Here we take the opportunity to find our type, doing, so we
        // do not have to iterate again
        this.applianceType = item.type ?? this.applianceType

        // If this item has controls we set then
        if (item.controls) {
          controls = item.controls
        }
        // if no controls we continue our search , will be undefined if
        // it is a leaf, but that does not matter.
        start = item
      })
    // Based on the type, we set the description, if possible
    this.resolveType()
    return controls
  }

  /**
   * Fins the most significant applianceType value
   * @private
   */
  public resolveType(): void {
    const type = applianceTypes.find((applianceType: ApplianceType) => applianceType.name === this.applianceType)
    if (type) {
      this.applianceTypeName = type.displayName
      this.nameEn = type.displayNameEn
    }
  }

  /**
   * Silly translator while I devleop, delete this after
   * we are done!
   */
  private translatePath(keys: string[]): TApplianceItemKey[] {
    if (keys.length === 0) {
      return []
    }
    let start = applianceTree.find(i => i.name === keys[0])
    const res = [start.key]
    keys.shift()
    keys
      .forEach((child: string) => {
        const item: IApplianceItem = start.options.find((c) => c.name === child)
        res.push(item.key)
        // If this item has controls we set then
        if (item.controls) {
          return
        }
        // if no controls we continue our search , will be undefined if
        // leaf, but that does not matter.
        start = item
      })
    this.applianceTree = [...res]
    return res
  }

  private translateTypes(): string[] {
    const items = [...this.applianceTree]
    return items.map(t => ApplianceTypeMap.get(t))
  }
}
