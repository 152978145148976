import {HttpClient} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {BehaviorSubject, concat, last, Observable, of} from 'rxjs'
import {map} from 'rxjs/operators'
import {environment} from '../../environments/environment'
import {UserEmailToNameMap} from '../common/users-pipe/users.pipe'
import {CustomerProject} from '../customer/model/customer-project.class'
import {BallsTag, TBallTagOwner} from '../model/tags/balls-tag'
import {MissingStateTag} from '../model/tags/missing-state-tag'
import {PhaseBallTag} from '../model/tags/phase-ball-tag'
import {PhaseTag} from '../model/tags/phase-tag'
import {ProdboardQuoteTag} from '../model/tags/prodboard-quote-tag'
import {ITag, PHASE_BALLS_TAG_ID, PHASE_TAG_ID} from '../model/tags/types'
import {UserTag} from '../model/tags/user-tag'
import {WaitingForCustomerTag} from '../model/tags/waiting-for-customer-tag'
import {IProject, IProjectBase} from './project-types'

export interface ITagInput {
  /**
   * The ID of the parent object.
   */
  id: string
  action?: 'ADD' | 'REMOVE' | 'UPDATE'

  // Only id and type normally needed.
  tag: Partial<ITag>
}

@Injectable({
  providedIn: 'root'
})
export class TagService {

  /**
   * Shallow list of user tags, these are not for real.
   */
  public userTags: UserTag[] = []

  /**
   * Listen to this when you want new tags, tags only, not
   * the complete project.
   */
  public tagChanged$ = new BehaviorSubject<Pick<IProject, 'id' | 'tags'>>({
    id: '',
    tags: []
  })

  constructor(
    private http: HttpClient
  ) {
    Object.keys(UserEmailToNameMap)
      .forEach(userEmail => {
        this.userTags.push(new UserTag(userEmail))
      })
  }

  /**
   * Creates tag objects from tag types. This is basically
   * a factory.
   */
  public static createTags(tags: Partial<ITag>[], project: IProjectBase): ITag[] {
    return tags
      .map(t => {
        t.project = project
        if (t.type === 'u') {
          return new UserTag(t.id)
        }
        if (t.type === 'uf') {
          return new MissingStateTag()
        }
        if (t.type === 'w') {
          return new WaitingForCustomerTag()
        }
        if (t.type === 'ph') {
          return new PhaseTag(t)
        }
        if (t.type === 'pq') {
          return new ProdboardQuoteTag()
        }
        if (t.type === 'b') {
          return new BallsTag(t as BallsTag)
        }
        if (t.type === 'pb') {
          return new PhaseBallTag(t as PhaseBallTag)
        }
      }).filter(t => !!t)
  }

  /**
   * Returns all tags, _except_ the user tag. Update this
   * when you add new tags.
   */
  public getAllTags = (): ITag[] => {
    return [
      new BallsTag({})
    ]
  }

  /**
   * Adds a tag to an item. Currently only "projects" are supported
   * @param tag
   * @param project - The project is first checked for tags
   */
  public tagProject(tag: Partial<ITag>, project: IProjectBase): Observable<Partial<ITag>[]> {

    if (this.tagExist(tag, project)) {
      return of(project.tags)
    }
    project.tags.push(tag)
    this.tagChanged$.next(project)
    const data: ITagInput = {
      id: project.id,
      tag: tag.getRawValue(),
      action: 'ADD'
    }
    const url = `${environment.productUrl}/tags`
    return this.http.put<ITag[]>(url, data)
  }

  /**
   * Removes a tag from an item. Currently only
   * @param tag - The tag to remove, removed by ID only
   * @param project - The project that potentially have the tag
   */
  public unTagProject(tag: Partial<ITag>, project: IProjectBase): Observable<Partial<ITag>[]> {
    if (!this.tagExist(tag, project)) {
      return of(project.tags)
    }
    project.tags = project.tags.filter(t => t.id !== tag.id)
    this.tagChanged$.next(project)
    const data: ITagInput = {
      id: project.id,
      // Only ID is needed for removal
      tag: {id: tag.id},
      action: 'REMOVE'
    }
    const url = `${environment.productUrl}/tags`
    return this.http.put<ITag[]>(url, data)
  }

  /**
   * Update existing tag, remove it and add it to the project.
   * @param tag
   * @param project
   */
  public updateTagProject(tag: Partial<ITag>, project: IProjectBase): Observable<Partial<ITag>[]> {
    // Remove the tag
    project.tags = project.tags.filter(t => t.id !== tag.id)
    // Add the new tag
    project.tags.push(tag)
    this.tagChanged$.next(project)
    const data: ITagInput = {
      id: project.id,
      tag: tag.getRawValue(),
      action: 'UPDATE'
    }
    const url = `${environment.productUrl}/tags`
    return this.http.put<ITag[]>(url, data)
  }

  /**
   * We need to run a series of checks based on the customer project.
   * @param customerProject
   * @param project
   */
  public checkCustomerProject(customerProject: CustomerProject, project: IProject): Observable<Partial<ITag>[]> {
    const obs: Observable<Partial<ITag>[]>[] = []
    obs.push(this.tagOrUntag(customerProject.shouldHavePQTag(), new ProdboardQuoteTag(), project))
    obs.push(this.tagOrUntag(customerProject.isHollow, new MissingStateTag(), project))
    obs.push(this.tagOrUntag(customerProject.isWaitingForCustomer(), new WaitingForCustomerTag(), project))
    obs.push(this.checkPhaseTag(customerProject, project))
    obs.push(this.checkPhaseBallTag(customerProject, project))
    obs.push(...this.processAutoTags(customerProject, project))
    return concat(...obs).pipe(
      last(),
      map(() => project.tags)
    )
  }

  private processAutoTags(customerProject: CustomerProject, project: IProject): Observable<any>[] {
    // Populates a Map.
    customerProject.checkForWaitingStates()

    // Get current Project auto tags
    const currentTags = this.getAutoTags(project)

    // Analyse CustomerProject waiting map and get tags to be removed and added.
    // Also, it will get the tags to check, which will be in the project,
    // but might be needing an update.
    const tagsToBeRemoved: BallsTag[] = []
    const tagsToUpdate: BallsTag[] = []
    const tagsToKeep: BallsTag[] = []
    const tagsToBeAdded: BallsTag[] = []
    customerProject.waitMap.forEach((action, owner: TBallTagOwner) => {
      const foundTags = currentTags.filter(t => t.owner === owner)
      if (foundTags.length === 0) {
        // Action is not present in tags, it needs to be added
        tagsToBeAdded.push(new BallsTag({
          auto: action.id,
          description: action.d,
          owner: owner,
          dueDate: new Date(action.ts).toISOString()
        }))
      } else {
        // We have some tags with same owner already present.
        // Now we need to check them (it, we only care about one).
        const foundTag = foundTags[0]

        const cond = customerProject.getConditionById(action.id)
        // Update remaining tag if needed:
        //  - Action's "id" and tag's "auto" are not matching
        //  - Action's condition's deadline and tag's due date are not matching
        if (foundTag.auto !== action.id ||
          (cond.deadline &&
            Math.abs(new Date(foundTag.dueDate).getTime() - cond.deadline) > 0)
        ) {
          foundTag.dueDate = cond.deadline ? new Date(cond.deadline).toISOString() : undefined
          foundTag.description = cond.label
          foundTag.auto = cond.id
          tagsToUpdate.push(foundTag)
        } else {
          tagsToKeep.push(foundTag)
        }
      }
    })

    // Then, we compare the "new to be project tags" with the old ones.
    // Those present in the old array and not in the new one will be removed.
    const newToBeTags = [].concat(tagsToKeep, tagsToBeAdded, tagsToUpdate)
    tagsToBeRemoved.push(
      ...currentTags.filter(currentTag =>
        !newToBeTags.some(t => t.id === currentTag.id))
    )

    // Get observables, API requests, and return them
    const toRemove = tagsToBeRemoved
      .map(t => this.unTagProject(t, project))
    const toAdd = tagsToBeAdded
      .map(t => this.tagProject(t, project))
    const toChange = tagsToUpdate
      .map(t => this.updateTagProject(t, project))
    return [].concat(toRemove, toChange, toAdd)
  }

  private getAutoTags(project: IProject): BallsTag[] {
    return project.tags
      .filter((t: Partial<ITag>): t is BallsTag => t.type === 'b')
      .filter(t => t.auto)
  }

  private checkPhaseTag(customerProject: CustomerProject, project: IProjectBase): Observable<Partial<ITag>[]> {
    // It is either there or not
    let isNewTag = false
    let change = false
    let tag: PhaseTag = project.tags
      .find((t: ITag): t is PhaseTag => t.id === PHASE_TAG_ID)

    if (!tag) {
      tag = new PhaseTag()
      isNewTag = true
    }
    if (tag.state !== customerProject.nextStateName.state) {
      tag.state = customerProject.nextStateName.state
      change = true
    }

    if (isNewTag) {
      return this.tagProject(tag, project)
    } else if (!isNewTag && change) {
      return this.updateTagProject(tag, project)
    }

    // If no changes return the empty array.
    return of([])
  }

  private checkPhaseBallTag(customerProject: CustomerProject, project: IProjectBase) {
    // Get PhaseBall tag (if present)
    const pbTag = project.tags
      .find((t: ITag): t is PhaseBallTag => t.id === PHASE_BALLS_TAG_ID)

    // If PhaseBall tag has a different state from the
    // current CustomerProject state, remove it!
    if (pbTag && pbTag.state !== customerProject.nextStateName.state) {
      return this.unTagProject(pbTag, project)
    } else if (!pbTag) {
      // If there is no PhaseBall tag, we need to check if there is any
      // condition, already selected, that should add a PhaseBall tag.
      // This case can happen when jumping between states.
      let foundTagToOwner: TBallTagOwner | undefined
      customerProject.states
        .filter(s => s.state.state === customerProject.nextStateName.state)
        .forEach(s => s.conditions
          .filter(c => c.selection)
          .forEach(c => c.advancedOptions
            ?.filter(a => a.name === c.selection && a.action.tagToOwner)
            .forEach(a => foundTagToOwner = a.action.tagToOwner)
          )
        )
      if (foundTagToOwner) {
        const newPbTag = new PhaseBallTag({
          owner: foundTagToOwner,
          state: customerProject.nextStateName.state
        })
        return this.tagProject(newPbTag, project)
      }
    }

    // Do nothing if there is no PhaseBall tag or if it is in correct state.
    return of([])
  }

  private tagExist(tag: Partial<ITag>, project: IProjectBase): boolean {
    return !!project.tags.find(t => t.id === tag.id && t.type === tag.type)
  }

  private tagOrUntag(have: boolean, tag: ITag, project: IProjectBase): Observable<Partial<ITag>[]> {
    if (have) {
      return this.tagProject(tag, project)
    }
    return this.unTagProject(tag, project)
  }
}
