import {Injectable} from '@angular/core'
import {CounterTop} from '../../counter-top/model/counter-top'
import {CabinetOption, ViewOption} from '../../model/cabinet-option'
import {ProdboardCabinet} from '../../model/cabinet/prodboard-cabinet'
import {STANDARD_SKIRTING} from '../../model/model-types'
import {IProject, TCountryLanguageCode, TPaintProcessValue} from '../../services/project-types'
import {ICustomerStateCondition, StateId, stateIds} from '../customer-types'
import {CustomerProject} from '../model/customer-project.class'
import {Customer} from '../model/customer.class'

/**
 * Show or hide paint map
 */
const showValues = new Map<TPaintProcessValue, boolean>(
  [
    [1, false],
    [2, true],
    [3, false],
    [4, true],
    [5, true],
    [6, true],
    [7, false],
    [8, false]
  ]
)

const DEFAULT_STATE_D_ID: string = 'Dynamic-CSC-'
const DEFAULT_STATE_D_LABEL: string = 'Ordered'
type SupplierCondition = { id: string; label: string }

@Injectable({
  providedIn: 'root'
})
export class StateService {

  /**
   * Cabinets, customer and project is set from the outside
   * make sure these are set properly
   */
  public cabinets: ProdboardCabinet[] = []

  public customer: Customer

  public project: IProject

  private invoiceCount = new Map<TPaintProcessValue | 0, number>(
    [
      [0, 3], // Default value, not a paint process value per-se.
      [1, 3],
      [2, 2],
      [3, 3],
      [4, 2],
      [5, 2],
      [6, 2],
      [7, 2],
      [8, 2]
    ]
  )

  private functionMap: Map<string, (c: ICustomerStateCondition) => boolean>

  constructor() {
    this.functionMap =
      new Map<StateId, (condition: ICustomerStateCondition) => boolean>([
        ['SKIRTING', this.setSkirtingState], // Cabinets
        ['HANDLES', this.setHandleState], // Cabinets
        ['DOOR_ATTACHMENTS', this.setDoorAttachments], // Cabinets
        ['PRODBOARD_FILE', this.setProdboardFile], // Not cabinets but sill
        ['CUSTOMER_ADDRESS', this.checkCustomerAddress],
        ['PAINT_FINISHED', this.checkPaintProcessInvoice], // Cabinets, sort of
        ['SEND_INVOICE', this.setInvoiceThreeLabel], // - " -
        ['STENY', this.setStenyState], // Project counter tops
        ['LIGHTS', this.setLightsState], // Cabinets
        ['PAINTING_EMAIL', this.checkPaintProcessEmail],
        ['INVOICE_TWO', this.setInvoiceTwoLabel],
        ['DRAWER_INSERTS', this.setDrawerInserts], // Cabinets
        ['PRODBOARD_QUOTE', this.setProdboardQuote], // Na
        ['AGACCO', this.setCarpentry],
        // Does not do anything here
        ['SPECIAL_PAINT', () => false],
        ['SEND_WHAT_NEXT_EMAIL', () => false],
        ['KGH_EMAIL', (condition: ICustomerStateCondition) => this.checkProjectCountry(condition, 'nb')],
      ])
  }

  /**
   * A number of conditions have "rules" doing things. E.g. set to
   * complete if there are cabinets. Or as in the case of paint, hide
   * an item if the item is not relevant.
   *
   * Filter out all states that have a condition (stateId). Run
   * a function for each. The function should take a condition and
   * return true/false if the function changed the completion state.
   *
   * The function causes side effects since it modifies the condition
   *
   * You must add the stateId to stateIds array in customer-types.ts and
   * of course to the condition
   *
   * @param customerProject
   */
  public processConditions(customerProject: CustomerProject): boolean {
    const conditions: ICustomerStateCondition[] = customerProject.stateItems
    return conditions
      // Remove all conditions that do not have a state id.
      .filter((condition: ICustomerStateCondition) =>
        stateIds.indexOf(condition.stateIds[0]) !== -1)
      .map((condition: ICustomerStateCondition) => {
        const changes =
          condition.stateIds
            .map(c => this.functionMap.get(c)(condition))
            .some(changed => changed)
        // Updated related states if there has been a change
        if (changes) {
          customerProject.updateRelatedStates(condition)
        }
        return changes
      })
      .some((acc: boolean) => acc)
  }

  /**
   * Method that will process project appliances to modify Customer Project's
   * state D (step 4. Order). It will create or remove checkboxes (conditions)
   * depending on the appliance suppliers.
   * For every supplier, a condition with "Ordered from <name_of_supplier>?"
   * will be created.
   * Also, all conditions that have no supplier in the list will be removed,
   * unless they are checked already. In that case, they will be kept.
   * @param customerProject
   */
  public processApplianceAndCounterTopSuppliers(customerProject: CustomerProject): boolean {
    // We get a unique list of all suppliers (its name) in the appliances and
    // counter tops. Then we create checkbox labels for all of them.
    const allSuppliers = this.project.appliances
      .map(a => a.supplier.name)
      .concat(this.project.counterTops
        .map(ct => ct.supplier).filter(s => s))
    const supplierConditions: SupplierCondition[] = new Array(
      ...new Set(allSuppliers))
      .map(name => {
        return {
          id: `${DEFAULT_STATE_D_ID}${name}`,
          label: `${DEFAULT_STATE_D_LABEL} from ${name}?`
        }
      })

    // Get all conditions for phase "4. Order", which is label "D".
    const currentConditions =
      customerProject.getStateByLabel('D').conditions

    /**
     * Retro-compatibility stuff here:
     * If we find any condition with label "Ordered xxxxx?" that is
     * complete and has static ID (the old ID in customer-state-map.ts), we will
     * leave it there.
     * Also, all conditions with label different from "Ordered xxxxx?" are
     * untouchable too.
     */
    const untouchableConditionLabels: string[] = currentConditions
      .filter(c => c.completed)
      .filter(c => !c.id.startsWith(DEFAULT_STATE_D_ID))
      .concat(
        // Concat all conditions that have nothing to do with the
        // "Ordered xxxx?" ones. There are more conditions in state D.
        currentConditions
          .filter(c =>
            !c.label.startsWith(DEFAULT_STATE_D_LABEL) &&
            !c.id.startsWith(DEFAULT_STATE_D_ID))
      )
      .map(c => c.label)

    // We need a flag to mark if there has been any changes
    let changed: boolean = false

    // Remove all conditions that are not present in "conditionLabels".
    // Unless they are "untouchable" of course.
    currentConditions
      .filter(c => !untouchableConditionLabels.includes(c.label))
      .filter(c =>
        !supplierConditions.some(sc => sc.id === c.id))
      .forEach(c => {
        currentConditions.splice(currentConditions.indexOf(c), 1)
        changed = true
      })

    // Update existent ones, only if needed.
    currentConditions
      .filter(c => !untouchableConditionLabels.includes(c.label))
      .forEach(c => {
        // At this point, currentConditions have all untouchable ones and those
        // coinciding with conditionLabels.
        // We just update label, nothing else, if it's different
        const supplierCondition =
          supplierConditions.find(sc => sc.id === c.id)
        if (supplierCondition && supplierCondition.label !== c.label) {
          c.label = supplierCondition.label
          changed = true
        }
      })

    // Add all non-existent ones
    supplierConditions
      .filter(sc => !untouchableConditionLabels.includes(sc.label))
      .filter(sc =>
        !currentConditions.some(c => c.id === sc.id))
      .forEach(sc => {
        // Create a new condition and add it to the array
        const newCondition: ICustomerStateCondition = {
          id: sc.id,
          label: sc.label,
          // Position is a bit tricky, the previous static ones in the old
          // customer-state-map.ts, had positions 10-20, so they will all be
          // 10 now, and they will organise themselves as they want but all
          // together
          position: 10,
          completed: false,
          type: 'BINARY',
          deadline: Date.now() + 14 * 24 * 60 * 60 * 1000 // 14 Days
        }
        currentConditions.push(newCondition)
        changed = true
      })

    return changed
  }

  /**
   * If any drawer-insert of type cutlery divider make sure they all have
   * comments. If they do, mark complete. If no cutlery dividers then disable
   * the item.
   * @param condition
   */
  private setDrawerInserts = (condition: ICustomerStateCondition): boolean => {
    // This shall not be checked if no cabinets to look at.
    if (this.cabinets.length === 0) {
      return false
    }
    const initial = condition.completed + '' + condition.notApplicable
    const drawerInserts: CabinetOption[] = this.cabinets
      .map((cabinet: ProdboardCabinet) => cabinet.hasDrawerInserts)
      .filter(Boolean)

    let hasCutleryDivider = false // Check if drawerInserts has cutely divider
    for (const drawerInsert of drawerInserts) {
      // Find the viewOption object with the name "cutleryDiv"
      const cutleryDivOption: ViewOption = drawerInsert.viewOptions
        .find((option) => option.name === 'cutleryDiv')

      // Check if it has 1 or more cutelyDividers
      hasCutleryDivider = +cutleryDivOption.selection > 0
    }
    condition.notApplicable = !hasCutleryDivider

    // Make sure _every_ DrawerInsert has a comment, every on empty list
    // returns true, hence we reduce.
    const allHaveComment: boolean =
      drawerInserts.length > 0 &&
      drawerInserts
        .every((cabinetOption: CabinetOption) => cabinetOption.comments.length > 0)
    // If it is complete it is complete, otherwise complete if all have comments
    condition.completed = condition.completed || allHaveComment
    // If all have comments, we disable the selection
    condition.disabled = allHaveComment
    return initial !== condition.completed + '' + condition.notApplicable
  }

  private setInvoiceTwoLabel = (condition: ICustomerStateCondition): boolean => {
    const initialLabel = condition.label
    // Three invoices is default, from type one.
    const paintProcess = this.project.form.paintProcess ?? 1
    // This is WRONG we should instead have two conditions, se also note in
    // Customer project
    condition.label = this.project.form.lc === 'nb' ?
      `Remind the customer about second payment` :
      `Send invoice 2 of ${this.invoiceCount.get(paintProcess)} to the customer`
    return condition.label !== initialLabel
  }

  private setInvoiceThreeLabel = (condition: ICustomerStateCondition): boolean => {
    const initialLabel = condition.label
    const initialNotApplicable = condition.notApplicable
    const paintProcess = this.project.form.paintProcess ?? 1

    condition.label = this.project.form.lc === 'nb' ?
      `Remind the customer about third payment` :
      `Send invoice 3 of ${this.invoiceCount.get(paintProcess)} to the customer`
    condition.notApplicable = showValues.get(this.project.form.paintProcess) ?? condition.notApplicable

    return !(condition.label === initialLabel || condition.notApplicable === initialNotApplicable)
  }

  private setLightsState = (condition: ICustomerStateCondition): boolean => {
    const initial = condition.notApplicable
    condition.notApplicable = !this.projectHasLights() ||
      this.project.form.carpentry !== 'Agacco'
    return initial !== condition.notApplicable
  }

  private projectHasLights = (): boolean => {
    // If we have no cabinets, this should not be verified.
    if (this.cabinets.length === 0) {
      return false
    }

    return this.cabinets.map((cabinet: ProdboardCabinet) => cabinet.hasLights)
      .reduce((acc: boolean, val: boolean) => acc || val, false)
  }

  private setStenyState = (condition: ICustomerStateCondition): boolean => {
    const initial = condition.notApplicable
    condition.notApplicable = !this.project.counterTops
      .some((ct: CounterTop) => ct.material === 'stone' &&
        (ct.supplier === 'Steny' || ct.supplier === 'Nerostein'))
    return initial !== condition.notApplicable
  }

  private setSkirtingState = (condition: ICustomerStateCondition): boolean => {
    if (this.cabinets.length === 0) {
      return false
    }
    const initial = condition.completed
    const skirting: CabinetOption[] = []
    this.cabinets.forEach((cabinet: ProdboardCabinet) => {
      const optionWithSkirt: CabinetOption = cabinet.options.find((option: CabinetOption) => option.optionSelectName === 'Skirting')
      if (optionWithSkirt) {
        skirting.push(optionWithSkirt)
      }
    })
    condition.disabled = !skirting.find((op: CabinetOption) => STANDARD_SKIRTING.indexOf(op.viewOptions[0].selection) !== -1)
    if (condition.disabled) {
      condition.completed = !skirting.find((op: CabinetOption) => STANDARD_SKIRTING.indexOf(op.viewOptions[0].selection) !== -1)
    }
    return initial !== condition.completed
  }

  private setHandleState = (condition: ICustomerStateCondition): boolean => {
    if (this.cabinets.length === 0) {
      return false
    }
    const initial = condition.completed
    this.cabinets.forEach((cabinet: ProdboardCabinet) => {
      const optionWithHandle: CabinetOption = cabinet.options.find((option: CabinetOption) => option.optionSelectName === 'HandleDoor')
      if (optionWithHandle) {
        if (optionWithHandle.viewOptions[0].selection === 'Ja') {
          condition.completed = true
        }
      }
    })
    return initial !== condition.completed
  }

  /**
   * Find all cabinets that have "Door attachment" option. Take the selection
   * from each if any of them have "TBD" this checklist item (condition) is
   * set to either complete or not. This item is set by us so it is disabled.
   *
   * @private
   */
  private setDoorAttachments = (condition: ICustomerStateCondition): boolean => {
    if (this.cabinets.length === 0) {
      return false
    }
    const initial = condition.completed
    const hanging: string[] = []
    this.cabinets.forEach((cabinet: ProdboardCabinet) => {
      const optionWithHanging: CabinetOption = cabinet.options.find((option: CabinetOption) => option.optionSelectName === 'DoorAttachment')
      if (optionWithHanging) {
        hanging.push(optionWithHanging.viewOptions[0].selection)
      }
    })
    condition.disabled = true
    condition.completed = hanging.indexOf('TBD') === -1
    return initial !== condition.completed
  }

  /**
   * Condition should be completed if more than 0 cabinets.
   */
  private setProdboardFile = (condition: ICustomerStateCondition): boolean => {
    const initial = condition.completed
    // This can never be undone! If you have a file, you have a file
    condition.completed = condition.completed || this.cabinets.length > 0
    return initial !== condition.completed
  }

  private checkCustomerAddress = (condition: ICustomerStateCondition): boolean => {
    const initial = condition.completed
    if (this.customer) {
      condition.completed = this.customer.validAddress()
    }
    return initial !== condition.completed
  }

  private checkProjectCountry = (condition: ICustomerStateCondition, countryCode: TCountryLanguageCode): boolean => {
    const initial = condition.notApplicable
    condition.notApplicable = this.project.form.lc !== countryCode
    return initial !== condition.notApplicable
  }

  /**
   * We use the same method for both paint process and the third invoice since it's
   * the same conditions apply if they should be shown or not
   */
  private checkPaintProcessInvoice = (condition: ICustomerStateCondition): boolean => {
    const initial = condition.notApplicable
    condition.notApplicable = showValues.get(this.project.form.paintProcess) ?? condition.notApplicable
    return initial !== condition.notApplicable
  }

  private checkPaintProcessEmail = (condition: ICustomerStateCondition): boolean => {
    const initial = condition.notApplicable
    // Checkbox should be hidden if paint process is 2, 4, 5, 6, 7 or 8
    condition.notApplicable = this.project.form.paintProcess === 2 ||
      this.project.form.paintProcess >= 4
    return initial !== condition.notApplicable
  }

  private setProdboardQuote = (condition: ICustomerStateCondition): boolean => {
    condition.notApplicable = this.project?.isOrder !== true
    // Always return false as this does not make any changes
    return false
  }

  private setCarpentry = (condition: ICustomerStateCondition): boolean => {
    const initial = condition.notApplicable
    // Currently set by actual name, shame.
    condition.notApplicable = this.project.form.carpentry !== 'Agacco'
    return initial !== condition.notApplicable
  }
}
