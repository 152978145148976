import {Component, OnDestroy, OnInit} from '@angular/core'
import {MatDialog} from '@angular/material/dialog'
import {Router} from '@angular/router'
import {Subscription} from 'rxjs'
import {filter} from 'rxjs/operators'
import {AutoSaveService} from '../../services/auto-save.service'
import {OpenProjectService} from '../../services/open-project.service'
import {IProjectFile} from '../../services/project-file-types'
import {IProject} from '../../services/project-types'
import {AutoSaveComponent} from '../auto-save/auto-save.component'
import {
  PriceSummaryDialogComponent
} from './price-summary-dialog/price-summary-dialog.component'

@Component({
  selector: 'kdl-floating-menu',
  templateUrl: './floating-menu.component.html',
  styleUrls: ['./floating-menu.component.scss']
})
export class FloatingMenuComponent extends AutoSaveComponent implements OnInit, OnDestroy {

  /**
   * Current open project
   */
  currentProject: IProject | null = null

  /**
   * Unless we have project we hide ourselves.
   */
  public showFloater = false

  public prodboardUrl: string

  private sub$ = [new Subscription()]

  constructor(
    autoSave: AutoSaveService,
    router: Router,
    dialog: MatDialog,
    private openProjectService: OpenProjectService,
    private matDialog: MatDialog
  ) {
    super(autoSave, router, dialog)
  }

  public ngOnInit(): void {
    super.ngOnInit()

    this.sub$.push(this.openProjectService.project$
      .subscribe({
        next: (project: IProject | null) => {
          this.showFloater = project !== null
          this.currentProject = project
        }
      }))

    this.sub$.push(this.openProjectService.projectFile$
      .pipe(filter(Boolean))
      .subscribe({
        next: (file: IProjectFile) => this.prodboardUrl = file.prodboardUrl
      }))
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy()
    this.sub$.forEach(s => s.unsubscribe())
  }

  public saveProject(): void {
    this.autoSave.saveChanges()
  }

  public openPriceDialog(): void {
    PriceSummaryDialogComponent.open(this.matDialog)
  }
}
