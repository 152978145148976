import {DefaultMap} from '../../application/helpers'
import {
  Comment,
  CommentHomeEntity,
  Comments
} from '../../comments/model/comment'
import {
  IUnitPrice,
  TWhiteGoodsTypeKey
} from '../../common/interface/product-types'
import {IProdboardComment} from '../../services/prodboard.service'
import {ProductCategory} from '../../services/product-static.service'
import {
  I3DCoordinates,
  TProjectFileOptionName
} from '../../services/project-file-types'
import {IProject, TPaintProcessValue} from '../../services/project-types'
import {SettingsItemService} from '../../services/settings-item.service'
import {BackPanel} from '../back-panel'
import {BrassPlate} from '../brass-plate'
import {CabinetOption, TOptionSelectName} from '../cabinet-option'
import {CabinetSettings} from '../cabinet-settings/cabinet-setting'
import {CarpenterJoy} from '../carpenter-joy'
import {CenterPost} from '../center-post'
import {CombinedUnit} from '../combined-unit'
import {Cornice} from '../cornice'
import {CoverSide} from '../cover-side'
import {CuttingBoard} from '../cutting-board'
import {Door} from '../door'
import {DoorAttachment} from '../door-attachment'
import {DoorType} from '../door-type'
import {DrawerDoor} from '../drawer-door'
import {DrawerFront} from '../drawer-front'
import {DrawerInsert} from '../drawer-insert'
import {FanAdoption} from '../fan-adoption'
import {FanExtractorPanel} from '../fan-extractor-panel'
import {Filler} from '../filler'
import {FrameWidth} from '../frame-width/frame-width'
import {FrameWidthHelper} from '../frame-width/frame-width-helper'
import {HandleDoor} from '../handle-door'
import {HandleDrawer} from '../handle-drawer'
import {Hanging} from '../hanging'
import {HiddenDrawer} from '../hidden-drawer'
import {HiddenDrawerSink} from '../hidden-drawer-sink'
import {HiddenVisibleWidth} from '../hidden-visible-width'
import {Hinges} from '../hinges'
import {Legs} from '../legs'
import {Lighting} from '../lighting'
import {
  BackPanelMill,
  BrassPlateMill,
  CarpenterJoyMill,
  CenterPostMill,
  CombinedUnitMill,
  CorniceMill,
  CoverSideMill,
  CuttingBoardMill,
  DoorAttachmentMill,
  DoorMill,
  DoorTypeMill,
  DrawerDoorMill,
  DrawerFrontMill,
  DrawerInsertMill,
  FanAdoptionMill,
  FanExtractorPanelMill,
  FillerMill,
  FrameWidthMill,
  HandleDoorMill,
  HandleDrawerMill,
  HangingMill,
  HiddenDrawerMill,
  HiddenDrawerSinkMill,
  HiddenVisibleWidthMill,
  HingesMill,
  IMillFileItem, IMillItemPosition,
  IMillRoom,
  LegMill,
  LightingMill,
  PaintProcessMill,
  PaintSideMill,
  ScribingsMill,
  ShelvesAdjustableMill,
  ShelvesMill,
  SkirtingMill,
  SpiceRackMill,
  TMillOption
} from '../mill-file/mill-file-types'
import {
  DOOR_NO_DOOR_SELECTED,
  DOOR_ON_DOOR,
  DOOR_PAINTED_INSIDE,
  DRAWER_DOOR_DOOR
} from '../model-types'
import {PaintProcess} from '../paint-process'
import {PaintSide} from '../paint-side'
import {Plinth} from '../plinth'
import {Scribings} from '../scribings'
import {Shelves} from '../shelves'
import {ShelvesAdjustable} from '../shelves-adjustable'
import {Skirting} from '../skirting'
import {SpiceRack} from '../spice-rack'
import {CabinetLayout} from './cabinet-layout'
import {CabinetLighting} from './cabinet-lighting'
import {CabinetMaxHeight} from './cabinet-max-height'

interface SpecialOption extends IUnitPrice {
  id: 'higherThanStandard' | 'deeperThanStandard'
}

export class ProdboardCabinet implements Comments {

  /**
   * The UID received from Prodboard
   */
  public uid = ''

  public index: number

  public code: string

  public readonly cat: string

  public description: string

  public dimensions: I3DCoordinates = {x: 0, y: 0, z: 0}

  public room: IMillRoom = {
    beams: [],
    dimensions: {x: 0, y: 0, z: 0}
  }

  /**
   * This is accentual the room height including beams if any.
   * We use this to generate warnings if a cabinet is more than 10mm from the celling or a beam
   */
  public maxHeight = 2 * 1000 * 1000

  /**
   * Base price of cabinet, can be set in settings
   * defaults to product prices
   */
  public basePrice: number

  public baseLabor: number

  public baseMaterial: number

  /**
   * Description of what? In Swedish?
   */
  public deSwLaSw = ''

  /**
   * Eventually I will make this private :)
   */
  public options: CabinetOption[] = []

  public specialOptions: SpecialOption[] = []

  public comments: Comment[] = []

  public prodboardComment: IProdboardComment | undefined

  public hasSocel = false

  public isDishwasherCabinet = false

  public isBaseCornerCabinet = false

  public productComments = {
    sv: '',
    en: ''
  }


  public position: IMillItemPosition = {
    direction: {
      x: 0,
      y: 0,
      z: 0
    },
    center: {
      x: 0,
      y: 0,
      z: 0
    }
  }

  public drawers: number[] = []

  /**
   * These two variables a used when we override the prodboard data
   * in forms
   */
  public hWidth: number
  public vWidth: number

  /**
   * Let us expose our paint process so that the project
   * can select it. We convert the 'text' value to a number
   * that is suitable for the project
   */
  public paintProcess: TPaintProcessValue = 1

  /**
   * Visible and Hidden width are applicable only for "Corner" cabinets.
   * Hidden is used as marker, if > 0 we have both. Visible is normally
   * set to the width (x) of the cabinet.
   */
  public hiddenVisibleWidth: HiddenVisibleWidth | undefined

  public commentHome: CommentHomeEntity = {type: 'CABINET', id: this.uid}

  /**
   * If this cabinet is adapted for white goods.
   */
  public whiteGoodsAdaptation: TWhiteGoodsTypeKey | undefined
  /**
   * This can be read but not set by outsiders.
   */
  public readonly layout: CabinetLayout
  /**
   * This can be read but not set by outsiders.
   */
  public readonly lighting: CabinetLighting

  public readonly product: ProductCategory

  private pActualHeight = 0

  private readonly defaultProduct: any = {
    cat: 'EMPTY',
    pc: 'EMPTY',
    pr: {
      price: 0,
      labor: 0
    },
    exPrHiSt: {
      price: 0,
      labor: 0
    },
    exPrDeSt: {
      labor: 0,
      price: 0
    },
    shIdPr: {
      labor: 0,
      price: 0
    },
    frontFrame: {
      faLeFr: 0,
      faRiFr: 0
    },
    shDe: 0,
    scSe: 'default',
    dhs: [],
    nuDo: 0,
    nuDr: 0
  }

  private readonly ignoredTypes: Set<TOptionSelectName> = new Set(['UnknownOption', 'HandleDrawer'])

  private project: IProject

  private input: IMillFileItem = {
    code: '',
    dimensions: this.dimensions,
    index: 0,
    prodboardComment: {comment: '', deleted: true, id: '0'},
    options: {},
    position: this.position,
    uuid: ''
  }

  /**
   * The SettingItemService that sets option names.
   * We will only use one method "updateCabinetOption" for it.
   */
  private settingsItemService: SettingsItemService

  private settings: CabinetSettings = new CabinetSettings()

  /**
   * Three sensible defaults for handling the skirtings dependency
   * to fillers and frame
   */
  private skirting: Skirting = new Skirting({} as any, {} as any, 0)

  private frame: FrameWidth = new FrameWidth({} as any, {} as any, {
    index: 1,
    dimensions: {x: 450}
  } as any, new FrameWidthHelper())

  private fillers: Filler = new Filler({} as any, {} as any, 0)

  private currentSkirting = ''

  private baseSettings: CabinetSettings = new CabinetSettings()

  /**
   * We maintain a map of all options for quick access, do not access
   * this directly, use the accessor methods, getActiveOption etc.
   */
  private optionsMap = new DefaultMap<TOptionSelectName, CabinetOption[], CabinetOption[]>(
    [], []
  )

  /**
   * A very complex recalculation of a Prodboard cabinet combined with
   * the project data
   *
   * @param settingsItemService - Used to add new labels to options via "updateCabinetOption()".
   * @param product - The product from the product database.
   * @param input - The Prodboard Cabinet item, containing the options and items.
   * @param room - The room in which the cabinet is contained IRL.
   * @param project - The project data for the current project
   */
  constructor(
    settingsItemService: SettingsItemService = {} as any,
    product: ProductCategory = {} as any,
    input: IMillFileItem = {} as any,
    room: IMillRoom = {dimensions: {x: 20000, y: 5000, z: 20000}, beams: []},
    project: IProject = {form: {}} as any
  ) {
    this.settingsItemService = settingsItemService
    this.project = project

    // At this stage we have defaults for all inputs.
    this.input = {...this.input, ...input}
    this.room = {...room}

    const cabinetMaxHeight = new CabinetMaxHeight()
    this.maxHeight = cabinetMaxHeight.calculateCabinetMaxHeight(this)

    // We do not want to fail if no prices. Or if the frames are not in place.
    this.product = {...this.defaultProduct, ...product}

    this.cat = product.cat
    this.code = product.pc
    this.productComments.sv = product.coSe
    this.productComments.en = product.co

    this.position = this.input.position

    this.basePrice = ProdboardCabinet.fixPrice(this.product.pr.price)
    this.baseLabor = ProdboardCabinet.fixPrice(this.product.pr.labor)
    this.baseMaterial = ProdboardCabinet.fixPrice(this.product.pr.material)

    /**
     * Guess if we have socel so that we can display that?
     * If no default socel, then no socel. And if 0 as default?
     */
    this.hasSocel = !!this.product.shDe

    this.description = product.tiLaSw
    this.deSwLaSw = product.deSwLaSw
    this.isDishwasherCabinet = this.product.idc

    this.isBaseCornerCabinet = this.product.ibcc
    this.whiteGoodsAdaptation = this.product.iwg
    this.product.dhs.forEach((height: number) => this.drawers.push(height))
    // From the prodboard file.
    this.index = input.index
    this.uid = input.uuid

    this.commentHome.id = this.uid
    this.prodboardComment = this.input.prodboardComment

    this.dimensions = {...this.dimensions, ...this.input.dimensions}
    // Adjust depth for dishwashers, i.e. they are actually on 25 mm deep,
    // Also height is fixed to 777
    if (this.isDishwasherCabinet) {
      this.dimensions.z = 25
      this.dimensions.y = 777

      // Also prevent the following options
      this.ignoredTypes.add('Hinges')
      this.ignoredTypes.add('Hanging')
      this.ignoredTypes.add('HandleDoor')
      this.ignoredTypes.add('HandleDrawer')
    }

    this.baseSettings.fromCabinet(this)

    // When we come here, we have a "product" from the product database
    // And an "item" from prodboard, we can now set interesting properties
    // based on the input.
    this.analyzeMillItem(this.input)

    /**
     * If this cabinet has the shDe we add this
     * as an additional option.
     */
    if (this.product.shDe) {
      const plinth = new Plinth(this.product, this.index)
      this.addOption(plinth)
    }

    this.options.forEach(o => {
      // Update texts
      this.settingsItemService.updateCabinetOption(o)
      // Needed to make sure all options have a proper id.
      o.setCommentHomeId(this.uid)
    })

    this.updateOptions()
    this.calculateCabinetPrice()

    // Calculate layout and lighting AFTER calculating CabinetOptions
    this.layout = new CabinetLayout(this)
    this.lighting = new CabinetLighting(this)

    this.options = CabinetOption.sortOptions(this.options)
  }

  get paintedInside(): boolean {
    return this.getOption<Door>('Door').some((door: Door) => door.viewOptions[1].selection === DOOR_PAINTED_INSIDE)
  }

  get hasStoppers(): boolean {
    return this.product.scSe === 'default'
  }

  get hiddenWidth(): number {
    if (this.hiddenVisibleWidth) {
      return this.hWidth || this.hiddenVisibleWidth.hiddenWidth
    }
    return 0
  }

  get hasDrawerInserts(): CabinetOption | undefined {
    return this.getActiveOption('DrawerInsert')
  }

  /**
   * If not a corner cabinet, the visible width is the same as width (x)
   */
  get visibleWidth(): number {
    if (this.hiddenVisibleWidth) {
      return this.vWidth || this.hiddenVisibleWidth.visibleWidth
    }
    return this.dimensions.x
  }

  /**
   * Returns true if this is considered an Oven cabinet.
   */
  get isOven(): boolean {
    return this.product.ioc
  }

  /**
   * Returns true if cabinet is pantry, tall corner cabinet like TD1C-tall or
   * TD1C-xtall.
   */
  get isPantry(): boolean {
    return this.product.rct === 'pan'
  }

  /**
   * Tells if this cabinet has only drawers and more
   * than one drawer. This is used to generate warnings
   * if frame - recess !== 20, but not if it is a sink-out
   */
  get drawersOnly(): boolean {
    return this.product.nuDr > 1 && this.product.nuDo < 1 && this.product.rct.indexOf('sink') === -1
  }

  get actualHeight(): number {
    return this.figureOutActualHeight()
  }

  /**
   * Return cabinet FRONT area in m2
   */
  get area(): number {
    return this.dimensions.x * this.dimensions.y / 1000 / 1000
  }

  /**
   * Return cabinet volume in m3
   */
  get volume(): number {
    return this.dimensions.x * this.dimensions.y * this.dimensions.z / 1000 / 1000 / 1000
  }

  get numberOfShelves(): number {
    return this.product.nuShSt
  }

  get numberOfDoors(): number {
    return this.product.nuDo
  }

  get numberOfDrawers(): number {
    return this.product.nuDr
  }

  get sockelHeight(): number {
    return this.product.shDe
  }

  get highestPoint(): number {
    if (this.position?.center) {
      return this.position.center.y + (this.dimensions.y / 2)
    }
    return 10000
  }

  get counterTopId(): string {
    return this.settings.counterTop
  }

  set counterTopId(id: string) {
    this.settings.counterTop = id
  }

  /**
   * Cabinet type is a shortcut for recess cabinet type (rct)
   * e.g. wall-exec, base, tall-exec etc.
   */
  get cabinetType(): string {
    return this.product.rct
  }

  set cabinetType(type: string) {
    this.product.rct = type
  }

  get hasLights(): boolean {
    return !!this.getActiveOption<Lighting>('Lighting')
  }

  private static fixPrice(value: any): number {
    if (isNaN(+value)) {
      return 0
    }
    return +value
  }

  public getScribings(): string {
    return this.getActiveOptions<Scribings>('Scribings')
      .map((option: Scribings) => option.viewOptions[0].selection)[0] || ''
  }

  /**
   * This will get you an option if we have one and it is active.
   * If we have several, it will return the first it finds.
   * @param name - The optionSelectName
   */
  public getActiveOption<T extends CabinetOption>(name: TOptionSelectName): T | undefined {
    return this.optionsMap.get(name).find((o: T) => o.active)
  }

  /**
   * This will return all options of name, active or not or
   * an empty array that you must not fiddle with.
   * @param name - The optionSelectName
   */
  public getOption<T extends CabinetOption>(name: TOptionSelectName): Readonly<T[]> {
    return this.optionsMap.get(name)
  }

  /**
   * Get a whole bunch of items, but only the active ones. This is
   * for the special cases like Jar rack etc.
   * @param name
   */
  public getActiveOptions<T extends CabinetOption>(name: TOptionSelectName): Readonly<T[]> {
    return this.optionsMap.get(name).filter((o: T) => o.active)
  }

  /**
   * Check if this should have an inside paint note, only run on request.
   */
  public checkIfPaintedInsideNote(): string {
    let paintedInsideNote = ''
    this.getOption<Door>('Door')
      .forEach((door: Door) => paintedInsideNote = door.paintedInsideNote)
    return paintedInsideNote
  }

  public checkSettingsDiff(): string[] {
    /**
     * A list of translation for the warning if values differ from Prodboard and Mill
     */
    const translation = {
      title: 'description',
      titleEn: 'description to carpentry',
      height: 'height',
      width: 'width',
      depth: 'depth',
      price: 'price',
      labor: 'carpentry cost',
      material: 'material cost',
      productCommentEn: 'comment to carpentry',
      productCommentSv: 'comment to customer',
      drawers: 'drawers',
      visibleWidth: 'visible width',
      hiddenWidth: 'hidden width'
    }
    // Some options are not relevant for some cabinets
    // If not a base corner cabinet, remove the hidden/visible
    if (!this.isBaseCornerCabinet) {
      delete translation.hiddenWidth
      delete translation.visibleWidth
    }

    // If no drawers we should not warn for that.
    if (this.numberOfDrawers === 0) {
      delete translation.drawers
    }
    const diffs = []
    Object.keys(translation).forEach((key: string) => {
      const setting = this.getSettingsValue(this.settings[key])
      const base = this.getSettingsValue(this.baseSettings[key])
      if (setting && setting !== base) {
        diffs.push(`${translation[key]} (${setting} <> ${base})`)
      }
    })
    return diffs
  }

  public getSettingsValue(value: any): string {
    if (!value) {
      return value
    }
    if (Array.isArray(value)) {
      return `[${value.join(',')}]`
    }
    return value + ''
  }

  /**
   * This is called to update cabinets with project data, this is called at two
   * occasions, 1, when we fetch a project from server, and 2) when we update
   * an option, the project data gets updated, and this is then called. So
   * it is guaranteed to be called when an option has changed.
   */
  public update(cabinetOptions?: Record<string, CabinetOption | Comment[]>): void {
    if (cabinetOptions) {
      this.comments = cabinetOptions.comments as Comment[] || []
      // Take all keys, but remove the 'comments' just in case.
      const keys = Object.keys(cabinetOptions).filter(k => k !== 'comments')
      // Filter out all options that are in the list of keys.
      this.options
        .filter((o: CabinetOption) => keys.indexOf(o.name) !== -1)
        .forEach((o: CabinetOption) => {
          const data = cabinetOptions[o.name] as CabinetOption
          o.update(data)
          // Special case that deactivates options that otherwise will activate themselves
          if (data.hasOwnProperty('active')) {
            o.active = data.active
          }
        })
    }
    this.updateOptions() //
    this.calculateCabinetPrice()
  }

  public getSettings(): CabinetSettings {
    return this.settings
  }

  /**
   * Override settings that take precedence over prodboard values (except index)
   *
   * @param settings
   */
  public setSettings(settings: CabinetSettings = new CabinetSettings()): void {
    Object.assign(this.settings, settings)

    /**
     * We have to move the center point when we change the size of a cabinet
     * otherwise we won't be abel to calculate the maximum height
     */
    if (settings.width) {
      this.dimensions.x = settings.width
      this.position.center.x = settings.width / 2
    }
    /**
     * Woot! Note that we try to override the "actual height", that
     * So if we have a height here the dimensions has to be
     * set as the difference between _current_ actual and y
     */
    if (settings.height) {
      this.dimensions.y = settings.height + (this.dimensions.y - this.pActualHeight)
      this.position.center.y = this.dimensions.y / 2
    }
    if (settings.depth) {
      this.dimensions.z = settings.depth
      this.position.center.z = settings.depth / 2
    }
    this.description = settings.titleEn || this.description
    this.basePrice = settings.price || this.basePrice
    this.baseLabor = settings.labor || this.baseLabor
    this.baseMaterial = settings.material || this.baseMaterial
    this.hWidth = settings.hiddenWidth || this.hiddenWidth
    this.vWidth = settings.visibleWidth || this.visibleWidth

    this.deSwLaSw = settings.title || this.deSwLaSw

    this.productComments.en = settings.productCommentEn || this.product.co
    this.productComments.sv = settings.productCommentSv || this.product.coSe
    /**
     *
     */
    this.drawers = settings.drawers || this.drawers
    if (this.hiddenVisibleWidth) {
      this.hiddenVisibleWidth.update({
        hiddenWidth: this.hiddenWidth,
        visibleWidth: this.visibleWidth
      })
      this.dimensions.x = this.hiddenWidth + this.visibleWidth
    }
    this.calculateCabinetPrice()
  }

  public resetSettings(): void {
    this.settings = new CabinetSettings()
    this.setSettings(this.baseSettings)
  }

  private calculateCabinetPrice(): void {
    this.specialOptions.length = 0
    const actualHeight = this.figureOutActualHeight()
    if (actualHeight > this.product.hiSt) {
      this.specialOptions.push({
        id: 'higherThanStandard',
        price: ProdboardCabinet.fixPrice(this.product.exPrHiSt.price),
        labor: ProdboardCabinet.fixPrice(this.product.exPrHiSt.labor)
      })
    }

    if (this.dimensions.z > this.product.deSt) {
      this.specialOptions.push({
        id: 'deeperThanStandard',
        price: ProdboardCabinet.fixPrice(this.product.exPrDeSt.price),
        labor: ProdboardCabinet.fixPrice(this.product.exPrDeSt.labor)
      })
    }

    this.options
      .filter(o => o.active)
      .forEach((option: CabinetOption) => {
        option.setOptionValues(this)
      })
  }

  private analyzeMillItem(item: IMillFileItem): void {
    const map: Record<any, (opt: TMillOption) => CabinetOption | CabinetOption[] | null> = {
      backPanel: (opt: BackPanelMill) =>
        new BackPanel(opt, this.product, this.index),
      brassPlate: (opt: BrassPlateMill) =>
        new BrassPlate(opt, this.product, this.index),
      carpenterJoy: (opt: CarpenterJoyMill) =>
        new CarpenterJoy(opt, this.product, this.index),
      centerPost: (opt: CenterPostMill) =>
        new CenterPost(opt, this.product, this.index),
      combinedUnit: (opt: CombinedUnitMill) =>
        new CombinedUnit(opt, this.product, this.index),
      cornice: (opt: CorniceMill) =>
        new Cornice(opt, this.product, this.index),
      coverSide: (opt: CoverSideMill) =>
        opt.sides.map((s, count) =>
          new CoverSide(s, count, this.product, this.index)),
      cuttingBoard: (opt: CuttingBoardMill) =>
        new CuttingBoard(opt, this.product, this.index),
      doors: (doors: DoorMill[]) =>
        doors.map((door, index) => new Door(door, this.product, this.index, index)) as CabinetOption[],
      doorAttachment: (opt: DoorAttachmentMill) =>
        new DoorAttachment(opt, this.product, this.index),
      doorType: (opt: DoorTypeMill) =>
        new DoorType(opt, this.product, this.index),
      drawerDoor: (opt: DrawerDoorMill) =>
        new DrawerDoor(opt, this.product, this.index),
      drawerFront: (opt: DrawerFrontMill) =>
        new DrawerFront(opt, this.product, this.index),
      drawerInsert: (opt: DrawerInsertMill) =>
        new DrawerInsert(opt, this.product, this.index),
      fanAdoption: (opt: FanAdoptionMill) =>
        new FanAdoption(opt, this.product, this.index),
      fanExtractorPanel: (opt: FanExtractorPanelMill) =>
        opt.sides.map((s, count) =>
          new FanExtractorPanel(s, count, this.product, this.index)),
      // TODO - Both, filler and frameWidth have different values in V1 and V2.
      //  However, we are mapping both correctly, it's just PB values that are
      //  different for the same type of cabinet. Maybe check this?
      filler: (opt: FillerMill) =>
        new Filler(opt, this.product, this.index),
      frameWidth: (opt: FrameWidthMill) =>
        new FrameWidth(opt, this.product, this, new FrameWidthHelper()),
      handleDoors: (handleDoors: HandleDoorMill[]) =>
        handleDoors.map((handleDoor, index) => new HandleDoor(handleDoor, this.product, this.index, index)),
      handleDrawer: (opt: HandleDrawerMill) =>
        new HandleDrawer(opt, this.product, this.index),
      hanging: (opt: HangingMill) =>
        new Hanging(opt, this.product, this.index),
      hiddenDrawer: (opt: HiddenDrawerMill) =>
        new HiddenDrawer(opt, this.product, this.index),
      hiddenDrawerSink: (opt: HiddenDrawerSinkMill) =>
        new HiddenDrawerSink(opt, this.product, this.index),
      hiddenVisibleWidth: (opt: HiddenVisibleWidthMill) =>
        new HiddenVisibleWidth(opt, this.product, this.index),
      hinges: (opt: HingesMill) =>
        new Hinges(opt, this.product, this.index),
      legs: (opt: LegMill) =>
        new Legs(opt, this.product, this.index),
      lighting: (opt: LightingMill) =>
        new Lighting(opt, this.product, this.index),
      paintProcess: (opt: PaintProcessMill) =>
        new PaintProcess(opt, this.product, this.index),
      paintSide: (opt: PaintSideMill) =>
        new PaintSide(opt, this.product, this.index),
      // TODO - Missing property in PB V2 file.
      scribings: (opt: ScribingsMill) =>
        new Scribings(opt, this.product, this.index),
      // TODO - Mapping for V2 files seems weird but it is alright, the problem
      //  is that PB is not sending the correct values when the cabinets are
      //  wall cabinets (those starting with "O" like OD2).
      //  It happens with "shelves" AND "shelvesAdjustable".
      shelves: (opt: ShelvesMill) =>
        new Shelves(opt, this.product, this.index),
      shelvesAdjustable: (opt: ShelvesAdjustableMill) =>
        new ShelvesAdjustable(opt, this.product, this.index),
      skirting: (opt: SkirtingMill) =>
        new Skirting(opt, this.product, this.index),
      spiceRack: (opt: SpiceRackMill) =>
        new SpiceRack(opt, this.product, this.index)
    }

    Object.keys(item.options)
      .filter(Boolean)
      .filter(key => !!item.options[key])
      .forEach((key: TProjectFileOptionName) => {
        const millOption: TMillOption = item.options[key]
        const optionCreator = map[key]
        if (optionCreator) {
          const cabinetOption =  optionCreator(millOption)
          if (cabinetOption) {
            Array.isArray(cabinetOption) ?
              cabinetOption.forEach(co => this.addOption(co)) :
              this.addOption(cabinetOption)
          }
        }
      })

    // Check some special cases:
    // A) Hidden-Visible Width
    if (this.getOption('HiddenVisibleWidth').length > 0) {
      this.hiddenVisibleWidth = this.getOption<HiddenVisibleWidth>('HiddenVisibleWidth')[0]
      this.dimensions.x = this.hiddenVisibleWidth.hiddenWidth + this.hiddenVisibleWidth.visibleWidth
      this.dimensions.z = this.dimensions.z - 50
    } else {
      this.correctDepth()
    }
  }

  private addOption(option: CabinetOption): void {
    if (this.ignoredTypes.has(option.optionSelectName)) {
      return
    }

    const options = [...this.optionsMap.get(option.optionSelectName)]
    options.push(option)
    this.optionsMap.set(option.optionSelectName, options)
    this.options.push(option)
  }

  /**
   * Do some updates based on our options. This is called
   * also when we get updates from outside, not just when rendering
   */
  private updateOptions(): void {
    /**
     * Adjust frame and fillers based on skirting.
     */
    this.setFillersAndFrames()

    /**
     * If no door, then no hanging
     */
    this.removeHanging()

    /**
     * Remove Hinges, HandleDoor and HandleDrawer if DoorAttachment is equal to "Door on door".
     * This is because that means it's a refrigerator or freezer and shouldn't have these options
     */
    this.removeIfDoorOnDoor()
  }

  /**
   * Deactivates the hanging option if there is "NO DOOR" on this cabinet. You cannot
   * mix doors?
   */
  private removeHanging(): void {
    this.getOption<Door>('Door')
      .filter((o: CabinetOption) => o.viewOptions[0].selection === DOOR_NO_DOOR_SELECTED)
      .forEach(() => {
        this.getActiveOptions<Hanging>('Hanging')
          .forEach((o) => o.active = false)
      })

    this.getOption<DrawerDoor>('DrawerDoor')
      // If låda then no hanging / hinges
      .forEach((drawerDoor) => {
        this.getOption<Hinges>('Hinges')
          .forEach((o) =>
            o.active = drawerDoor.viewOptions[0].selection === DRAWER_DOOR_DOOR)
      })
  }

  /**
   * If we have Door attachment, and the attachment is Door-on-Door
   * then remove hinges and handles
   * @private
   */
  private removeIfDoorOnDoor(): void {
    this.getOption<DoorAttachment>('DoorAttachment')
      // Only doorAttachment can have "Door on Door"
      .filter((option: CabinetOption) => option.viewOptions[0].selection === DOOR_ON_DOOR)
      .forEach(() => {
        // There is normally just one so ...
        [
          this.getOption('Hinges'),
          this.getOption('HandleDoor'),
          this.getOption('HandleDrawer')]
          .flat()
          .forEach((option: CabinetOption) => option.active = false)
      })
  }

  /**
   * A very special case where recess (fillers) and frame needs
   * updating based on skirting.
   */
  private setFillersAndFrames(): void {
    // We should only update the values if the skirting has _changed_
    if (this.currentSkirting !== this.skirting.viewOptions[0].selection) {
      this.currentSkirting = this.skirting.viewOptions[0].selection
      this.fillers.setTopAndBottom(this.skirting, this.frame)
      this.frame.updateBasedOnSkirting(this.skirting)
    }
  }

  /**
   *
   */
  private figureOutActualHeight(): number {
    if (this.project.form.socelHeight === 0) {
      this.pActualHeight = this.dimensions.y
    } else {
      this.pActualHeight = this.dimensions.y - (this.product.shDe || 0)
    }

    return this.pActualHeight
  }

  /**
   * Corner cabinets BCP, BC, BCD2, should have a depth correction
   * of 50 mm.
   */
  private correctDepth(): void {
    if (this.product.ibcc === true) {
      this.dimensions.z = this.dimensions.z - 50
    }
  }
}
