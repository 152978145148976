import {
  CoverSideMill,
  I2DCoordinates,
  I3DCoordinates,
  PaintProcessMill
} from './mill-file-types'

export const DrawerInsertPossibilitiesV2 = [
  /^drawer_(\d)(\.\d)?$/,
  /^dr_(\d)(\.\d)?$/
]

/**
 * JSON emitted by Prodboard in it's V1. It will be used from now on
 * only to transpile this model to Prodboard V2 file. 17-09-2024 Darío.
 */
export interface IProdboardV2File {
  /**
   * ID of the project inside Prodboard.
   */
  prodboardId: string
  /**
   * Number of the project inside Prodboard.
   */
  prodboardNumber: string
  customer?: {
    name: string
    email?: string
  } | null
  /**
   * Project's URL in prodboard planner kitchen designing tool.
   * Like https://planner.prodboard.com/kulladal/kitchen#je7q5q0vjEKa1t8UM42AXA.
   */
  prodboardUrl: string
  /**
   * Planning of project's room and all items inside it.
   */
  plan: IProdboardV2Plan
}

/**
 * First level of Prodboard V2 JSON.
 * {plan}.
 *
 * Project's plan, which contains the room and all items inside it.
 */
export interface IProdboardV2Plan {
  items: IProdboardV2Item[]
  room: IProdboardV2Room
}

export interface IProdboardV2Room {
  dimensions: I3DCoordinates
  segments: IProdboardV2RoomSegment[]
}

export interface IProdboardV2RoomSegment {
  index: string
  start: I2DCoordinates
  end: I2DCoordinates
}

export interface IProdboardV2Item {
  uuid: string
  index: number
  code: string
  options: Record<string, any>
  dimensions: I3DCoordinates
  position: IProdboardV2ItemPosition
}

export interface IProdboardV2ItemPosition {
  center: I3DCoordinates
  direction: I3DCoordinates
}


///////////////////////////////////////////////////////////////////////
// PRODBOARD V2 OPTIONS
///////////////////////////////////////////////////////////////////////

export interface IProdboardV2Filler {
  top?: number
  left?: number
  right?: number
  bottom?: number
}

export interface IProdboardV2FrameWidth {
  top?: number
  left?: number
  right?: number
  bottom?: number
}

export interface IProdboardV2LegsAndSkirting {
  value?: '1. Regular plinth' | '2. Outside skirting'
  plinth_left?: boolean
  plinth_right?: boolean
  left_leg?: boolean
  right_leg?: boolean
  height?: number
}

export interface IProdboardV2CombinedUnit {
  top?: boolean
  bot?: boolean
  left?: boolean
  right?: boolean
}

export interface IProdboardV2DoorStyle {
  name?: string
  decor?: string
  smooth_facade?: 'Släta lådfronter'
}

export type TProdboardV2Hinges = 'old_hinges' | 'blum_hinges'

export interface IProdboardV2Shelves {
  q_shelves?: number
  shelves_type?: 'default' | 'adjustable'
}

export interface IProdboardV2Hanging {
  value?: 'left' | 'right' | 'top'
}

export interface IProdboardV2CenterPost {
  type?: 'off' | 'default' | 'fake'
  size?: number
}

export interface IProdboardV2PaintProcess {
  code?: string
  name?: typeof PaintProcessMill.values[number]
  // There are other parameters, but we don't care about them.
}

export interface IProdboardV2Door {
  hasDoor: boolean
  isPaintedInside: boolean
  doors: TProdboardV2Door
}

export type TProdboardV2Door = Record<string, {
  value?: 'Standardlucka' | 'Vitrinlucka'
}>

export interface IProdboardV2DoorAttachment {
  value: 'Door on door' | 'Slides' | 'TBD'
}

export interface IProdboardV2CoverSide {
  sides?: {
    side?: typeof CoverSideMill.sides[number]
    style?: string
    console?: string
    console_height?: number
    down_offset?: number
  }[]
}

export interface IProdboardV2BackPanel {
  style?: string
}

interface TProdboardV2Drawer {
  height?: number
  cutlery_tray?: string
  wavy_block?: boolean
}

interface IProdboardV2Drawers {
  brass_tag?: 'left' | 'right' | 'both'
}

export type TProdboardV2Drawers =
  IProdboardV2Drawers
  & Record<string, TProdboardV2Drawer>

export type TProdboardV2Filling = Record<string, IProdboardV2FillingElement>[]

export interface IProdboardV2FillingElement {
  code?: 'mix_drawer' | 'mix_shelf'
  h_drawer?: number
  offset?: number
}

export interface IProdboardV2PaintSide {
  left?: boolean
  right?: boolean
}

export type TProdboardV2HandleDoor = Record<string, {
  code?: string
  orientation?: string
}>

export type TProdboardV2SpiceRacks = Record<string, {
  value?: 'Kryddhylla' | 'Burkhylla' | 'Combo' | 'Ingen'
}>