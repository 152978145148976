import {DefaultMap} from '../application/helpers'
import {TLanguageCode} from '../application/i18n.provider'
import {IProduct, IUnitPrice} from '../common/interface/product-types'
import {TSettingOptionUseCase} from '../services/settings-item.service'
import {
  CabinetOption,
  TOptionSelectName,
  ViewOptionType
} from './cabinet-option'
import {ProdboardCabinet} from './cabinet/prodboard-cabinet'
import {Door} from './door'
import {HingesMill} from './mill-file/mill-file-types'
import {
  CLASSIC_HINGES,
  DOOR_NO_DOOR_SELECTED,
  MODERN_HINGES,
  MODERN_HINGES_TAP
} from './model-types'

export class Hinges extends CabinetOption {
  private readonly priceMap = new DefaultMap<string, IUnitPrice, IUnitPrice>([
    [CLASSIC_HINGES, {price: 262.5, labor: 0, material: 100}],
    [MODERN_HINGES, {price: 105, labor: 8, material: 0}],
    [MODERN_HINGES_TAP, {price: 0, labor: 0, material: 80}]
    // Anything else get zero
  ], {price: 0, labor: 0, material: 0})

  private numDoors: number

  constructor(option: HingesMill, product: IProduct, cabinetId: number) {
    super(product, cabinetId)

    this.priority = 10
    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Gångjärn',
        name: 'hinges',
        values: [CLASSIC_HINGES, MODERN_HINGES, MODERN_HINGES_TAP],
        selection: '',
        disabled: true
      }
    ]
    this.description = 'Decides what kind of hinge style the cabinet has - there are 3 different styles'
    this.title = 'optHinges'
    this.setValuesFromProdboardData(option)
    this.numDoors = this.product.nuDo || 0
    this.setPrice()
  }

  get optionSelectName(): TOptionSelectName {
    return 'Hinges'
  }

  public update(data: any): void {
    super.update(data)
    this.setFromProperties(data)
    this.setPrice()
  }

  public setOptionValues(cabinet: ProdboardCabinet) {
    cabinet.getOption<Door>('Door').filter(d => d.typeOfDoor() === DOOR_NO_DOOR_SELECTED)
      .forEach(() => this.numDoors = 0)
    this.setPrice()
  }

  public getCustomCustomerListing(
    useCase: TSettingOptionUseCase,
    lc: TLanguageCode
  ): string[] {
    return [this.settingOption.getI18n(this.viewOptions[0].selection, useCase, lc)]
  }

  private setValuesFromProdboardData(option: HingesMill): void {
    this.viewOptions[0].selection = option.value
    this.viewOptions[0].disabled = false
    this.active = true
  }

  private setPrice(): void {
    this.resetPrices()
    const findPrice: IUnitPrice = this.priceMap.get(this.viewOptions[0].selection)
    this.price = findPrice.price * this.numDoors
    this.labor = findPrice.labor * this.numDoors
    this.material = findPrice.material * this.numDoors
    if (this.price + this.labor + this.material > 0) {
      this.shouldHavePrice = true
    }
  }
}
