import {TLanguageCode} from '../application/i18n.provider'
import {CARDINALITY} from '../common/interface/helpers'
import {IProduct} from '../common/interface/product-types'
import {TSettingOptionUseCase} from '../services/settings-item.service'
import {
  CabinetOption,
  TOptionSelectName,
  ViewOption,
  ViewOptionType
} from './cabinet-option'
import {DrawerInsertMill} from './mill-file/mill-file-types'

interface IOptionData {
  property: 'cuDiPr' | 'knBlPr' | 'waBlPr'
  receiptText: string
}

export class DrawerInsert extends CabinetOption {
  constructor(option: DrawerInsertMill, product: IProduct, cabinetId: number) {
    super(product, cabinetId)

    this.priority = 10
    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Bestickinsats',
        name: 'cutleryDiv',
        values: ['0', '1', '2', '3', '4'],
        selection: '0',
        disabled: false,
        data: {
          property: 'cuDiPr',
          receiptText: 'Pris för ##NUM bestickinsats(er)'
        } as IOptionData
      },
      {
        type: ViewOptionType.select,
        title: 'Knivblock',
        name: 'knifeBlock',
        values: ['0', '1', '2', '3', '4'],
        selection: '0',
        disabled: false,
        data: {
          property: 'knBlPr',
          receiptText: 'Pris för ##NUM knivblock'
        } as IOptionData
      },
      {
        type: ViewOptionType.select,
        title: 'Kryddinsats',
        name: 'wavy',
        values: ['0', '1', '2', '3', '4'],
        selection: '0',
        disabled: false,
        data: {
          property: 'waBlPr',
          receiptText: 'Pris för ##NUM kryddinsats(er)'
        } as IOptionData
      }
    ]
    this.description = 'Dividers for drawers, knife blocks etc'
    this.title = 'optDrawerInsert'
    this.setValuesFromProdboardData(option)
    this.setPrice()
  }

  get optionSelectName(): TOptionSelectName {
    return 'DrawerInsert'
  }

  public update(data: any): void {
    super.update(data)
    this.setFromProperties(data)
    this.setPrice()
  }

  /**
   * For CAD, we just want the legs
   */
  public valueMap(): Record<string, string | number | boolean> {
    return {
      cutlery: Number.parseInt(this.viewOptions[0].selection, 10),
      knife: Number.parseInt(this.viewOptions[1].selection, 10),
      wavy: Number.parseInt(this.viewOptions[2].selection, 10)
    }
  }

  public getCustomCustomerListing(
    useCase: TSettingOptionUseCase,
    lc: TLanguageCode
  ): string[] {
    // We assume, blindly, that every viewOption has a corresponding
    // Setting Option Value.
    // Careful, do not use "filter" before "map". Otherwise, viewOptions don't
    // match with translation values.
    return this.viewOptions
      .map((option: ViewOption, i: number) => {
        if (+option.selection > 0) {
          return this.settingOption
              .getI18nFromValue(this.settingOption.values[i], useCase, lc)
            + ': ' + option.selection + ' ' + CARDINALITY[lc]
        } else {
          return ''
        }
      })
      .filter(s => s)
  }

  private setValuesFromProdboardData(option: DrawerInsertMill): void {
    this.viewOptions[0].selection = option.cutleryDiv + ''
    this.viewOptions[1].selection = option.knifeBlock + ''
    this.viewOptions[2].selection = option.wavy + ''
  }

  private setPrice(): void {
    this.resetPrices()
    this.active = false

    this.viewOptions
      .filter(vo => +vo.selection > 0)
      .forEach(vo => {
        this.addViewOptionPrice(vo)
        this.active = true
      })
  }

  private addViewOptionPrice(vo: ViewOption): void {
    const count = +vo.selection
    const property = vo.data.property

    this.price += count * this.product[property].price
    this.labor += count * this.product[property].labor
    this.material += count * this.product[property].material

    this.shouldHavePrice = true
  }
}
