import {hasch} from '../../common/interface/helpers'
import {
  BackPanelMill,
  BrassPlateMill,
  CarpenterJoyMill,
  CenterPostMill,
  CombinedUnitMill,
  CorniceMill,
  CoverSideMill,
  CuttingBoardMill,
  DoorAttachmentMill,
  DoorMill,
  DoorTypeMill,
  DrawerDoorMill,
  DrawerFrontMill,
  DrawerInsertMill,
  FanAdoptionMill,
  FanExtractorPanelMill,
  FillerMill,
  FrameWidthMill,
  HandleDoorMill,
  HandleDrawerMill,
  HangingMill,
  HiddenDrawerMill,
  HiddenDrawerSinkMill,
  HiddenVisibleWidthMill,
  HingesMill,
  IMillFile,
  IMillFileItem,
  LegMill,
  LightingMill,
  PaintProcessMill,
  PaintSideMill,
  ScribingsMill,
  ShelvesAdjustableMill,
  ShelvesMill,
  SkirtingMill,
  SpiceRackMill,
  TMillRoomBeam
} from './mill-file-types'
import {
  DrawerInsertPossibilitiesV1,
  HiddenDrawerPossibilitiesV1,
  IProdboardV1CabinetOption,
  IProdboardV1File,
  IProdboardV1SelectionItem
} from './prodboard-file-v1-types'
import {IProdboardV2File, IProdboardV2Item} from './prodboard-file-v2-types'

export class MillFileTransformer {
  public static transformProdboardFile(jsonFileAsString: string): IMillFile | null {
    // Convert file to JSON
    const jsonFile = JSON.parse(jsonFileAsString)

    let parsedFile: IMillFile | null = null
    // Check if it is V1
    if (this.isFileV1(jsonFile)) {
      parsedFile = this.transformV1File(jsonFile)
    }
    // Check if it is V2
    if (this.isFileV2(jsonFile)) {
      parsedFile = this.transformV2File(jsonFile)
    }
    return parsedFile
  }

  /**
   * We check if file is a Prodboard V1 file.
   * @param jsonFile
   * @private
   */
  private static isFileV1(jsonFile: any): boolean {
    // ID (when raw file) or Prodboard ID (once in system) is always a number.
    return jsonFile.hasOwnProperty('id') &&
      /^\d{1,6}$/.test(jsonFile.id) ||
      /^\d{1,5}$/.test(jsonFile.prodboardId)
  }

  /**
   * We check if file is a Prodboard V2 file.
   * @param jsonFile
   * @private
   */
  private static isFileV2(jsonFile: any): boolean {
    // "number" property should never be present.
    // ID and Prodboard ID should not be just a number. It's alphanumeric.
    return !jsonFile.hasOwnProperty('number') &&
      !/^\d{1,6}$/.test(jsonFile.id) &&
      jsonFile.hasOwnProperty('prodboardId') &&
      !/^\d{1,5}$/.test(jsonFile.prodboardId)
  }

  private static transformV1File(jsonFile: IProdboardV1File): IMillFile {
    const millItems: IMillFileItem[] = jsonFile.plan.items
      // Filter unwanted items like decoration, windows, etc.
      // We just want cabinets.
      .filter(itemHolder => {
        const forbiddenTypes = ['worktop', 'auxiliary', 'wall']

        return !forbiddenTypes.includes(itemHolder.item.type) &&
          Array.isArray(itemHolder.item.items) &&
          itemHolder.item.items.length > 0 &&
          itemHolder.item?.items?.find(itemOptions =>
            itemOptions.code === 'cabinet' ||
            itemOptions.modificator?.startsWith('ONdEx'))
      })
      // Sort all items by index
      .sort((a, b) =>
        a.item.index - b.item.index)
      .map(itemHolder => {
        const itemCode: string = itemHolder.item.items
          .find(i => i.code === 'cabinet' ||
            i.modificator?.startsWith('ONdEx'))!.modificator
        const commentHash = hasch(itemHolder.item.comments)

        const millItem: IMillFileItem = {
          uuid: itemHolder.uid,
          code: itemCode,
          index: itemHolder.item.index,
          options: {},
          prodboardComment: {
            id: commentHash,
            comment: itemHolder.item.comments ?? '',
            deleted: commentHash === '0'
          },
          dimensions: itemHolder.item.dimensions,
          position: {
            direction: itemHolder.position.direction,
            center: itemHolder.position.center
          }
        }

        itemHolder.item.items.forEach((item: IProdboardV1SelectionItem) => {
          switch (item.code) {
            case 'frames/json':
              millItem.options.frameWidth = FrameWidthMill.fromV1(item)
              break
            case 'fillers/json':
              millItem.options.filler = FillerMill.fromV1(item)
              break
            case 'legs/json':
              millItem.options.legs = LegMill.fromV1(item)
              break
            case 'hidden width':
            case 'visible width':
              millItem.options.hiddenVisibleWidth =
                HiddenVisibleWidthMill.fromV1(item, millItem.options.hiddenVisibleWidth)
              break
            case 'handle_door':
              millItem.options.handleDoors = HandleDoorMill.fromV1(item)
              break
            default:
            // Do nothing on purpose
          }
        })

        itemHolder.item.options.forEach((option: IProdboardV1CabinetOption) => {
          switch (option.name.toLowerCase()) {
            case 'anpassat för fläkt':
              millItem.options.fanAdoption = FanAdoptionMill.fromV1(option)
              break
            case 'brass tag':
              millItem.options.brassPlate = BrassPlateMill.fromV1(option)
              break
            case 'combined unit':
              millItem.options.combinedUnit = CombinedUnitMill.fromV1(option)
              break
            case 'doors':
              millItem.options.doorType = DoorTypeMill.fromV1(option)
              break
            case 'gångjärn':
              millItem.options.hinges = HingesMill.fromV1(option)
              break
            case 'handtag, låda':
              millItem.options.handleDrawer = HandleDrawerMill.fromV1(option)
              break
            case 'hyllor':
            case 'hyllor/lådor':
              millItem.options.shelves = ShelvesMill.fromV1(option)
              break
            case 'hyllor på luckans insida':
              millItem.options.spiceRack = SpiceRackMill.fromV1(option)
              break
            case 'hängning':
              millItem.options.hanging = HangingMill.fromV1(option)
              break
            case 'inbyggd belysning':
              millItem.options.lighting = LightingMill.fromV1(option)
              break
            case 'krönlist':
              millItem.options.cornice = CorniceMill.fromV1(option)
              break
            case 'mittstolpe':
              millItem.options.centerPost = CenterPostMill.fromV1(option)
              break
            case 'målning':
              millItem.options.paintProcess = PaintProcessMill.fromV1(option)
              break
            case 'släta lådfronter':
              millItem.options.drawerFront = DrawerFrontMill.fromV1(option)
              break
            case 'snickarglädje':
              millItem.options.carpenterJoy = CarpenterJoyMill.fromV1(option)
              break
            case 'sockel':
              millItem.options.skirting = SkirtingMill.fromV1(option)
              break
            case 'ställbara hyllplan':
              millItem.options.shelvesAdjustable = ShelvesAdjustableMill.fromV1(option)
              break
            case 'typ av lucka':
              millItem.options.doors = DoorMill.fromV1(option)
              break
            case 'luckinfästning':
              millItem.options.doorAttachment = DoorAttachmentMill.fromV1(option)
              break
            case 'ramens bredd (admin only)':
              millItem.options.scribings = ScribingsMill.fromV1(option)
              break
            case 'täcksida, höger':
            case 'täcksida, vänster':
              millItem.options.coverSide =
                CoverSideMill.fromV1(option, millItem.options.coverSide)
              break
            case 'täcksidor':
            case 'täcksida, baksida':
              millItem.options.backPanel = BackPanelMill.fromV1(option)
              break
            case 'utdragslådor':
              millItem.options.hiddenDrawerSink = HiddenDrawerSinkMill.fromV1(option)
              break
            case 'utdragbar skärbräda':
              millItem.options.cuttingBoard = CuttingBoardMill.fromV1(option)
              break
            case 'drawer/door':
              millItem.options.drawerDoor = DrawerDoorMill.fromV1(option)
              break
            case 'paint side because of dw':
              millItem.options.paintSide = PaintSideMill.fromV1(option)
              break
            case 'sida, front':
            case 'sida, vänster':
            case 'sida, höger':
              millItem.options.fanExtractorPanel =
                FanExtractorPanelMill.fromV1(option, millItem.options.fanExtractorPanel)
              break
            default:
              // Evaluate Drawer Inserts
              if (DrawerInsertPossibilitiesV1.some(possibility =>
                new RegExp(possibility, 'i').exec(option.name) !== null)) {
                millItem.options.drawerInsert =
                  DrawerInsertMill.fromV1(option, millItem.options.drawerInsert)
              }
              // Evaluate Hidden Drawers
              if (HiddenDrawerPossibilitiesV1.some(possibility =>
                new RegExp(possibility, 'i').exec(option.name) !== null)) {
                millItem.options.hiddenDrawer =
                  HiddenDrawerMill.fromV1(option, millItem.options.hiddenDrawer)
              }
          }
        })

        return millItem
      })

    const millBeams: TMillRoomBeam[] = jsonFile.plan.items
      .filter(itemHolder => itemHolder.item.code === 'Beam')
      .map(itemHolder => {
        return {
          uuid: itemHolder.uid,
          code: itemHolder.item.code,
          dimensions: itemHolder.item.dimensions,
          position: itemHolder.position
        }
      })

    return {
      isMilf: true,
      prodboardUrl: jsonFile.url,
      prodboardId: jsonFile.id?.toString(),
      prodboardNumber: jsonFile['number'],
      customer: {
        name: jsonFile.customer?.name ?? '',
        email: jsonFile.customer?.email,
        phone: jsonFile.customer?.phone
      },
      plan: {
        items: millItems,
        room: {
          dimensions: jsonFile.plan?.room?.dimensions ?? {x: 0, y: 0, z: 0},
          beams: millBeams
        }
      }
    }
  }

  private static transformV2File(jsonFile: IProdboardV2File): IMillFile {
    const millItems: IMillFileItem[] = jsonFile.plan.items
      // Sort all items by index
      .sort((a, b) =>
        a.index - b.index)
      .map((item: IProdboardV2Item) => {
        const millItem: IMillFileItem = {
          uuid: item.uuid,
          code: item.code,
          index: item.index,
          // In V2 there are no comment as such, but we need them in MillFile,
          // so we'll create an "already imported comment that means nothing".
          prodboardComment: {
            comment: '',
            id: '0',
            deleted: true
          },
          options: {},
          dimensions: item.dimensions,
          position: item.position
        }

        // TODO - In V1 we received all options that can be configured in Mill
        //  even if not active, they had a default value. However, in V2 we only
        //  receive the options that are active, which means that the rest of
        //  options that could be configured but are not active are unknown to us.
        //  That's why we are adding a "reasonable" list of default options
        //  depending on cabinet code. For example, all wall cabinets (those
        //  staring with "O", like OD2) can have "Cornice". So we add defaults.
        //  In a perfect world, Prodboard should send which are the configurable
        //  options per cabinet. Not only the active ones.
        // All cabinets
        millItem.options.lighting = {active: false, value: 'Ingen belysning'}
        // Wall cabinets (excluding shelves)
        if (millItem.code.startsWith('O') && !millItem.code.startsWith('OSH')) {
          millItem.options.hanging = {value: 'left'}
          millItem.options.cornice = {value: 'Nej'}
        }
        // Bench cabinets
        if (millItem.code.startsWith('B')) {
          millItem.options.skirting = {value: 'Standardsockel (inskjuten)'}
        }
        // Tall cabinets
        if (millItem.code.startsWith('T')) {
          millItem.options.cornice = {value: 'Nej'}
        }

        Object.keys(item.options).forEach((optionKey: string) => {
          const value: any = item.options[optionKey]

          switch (optionKey) {
            case 'frameWidth':
              millItem.options.frameWidth = FrameWidthMill.fromV2(value)
              break
            case 'filler':
              millItem.options.filler = FillerMill.fromV2(value)
              break
            case 'skirting':
              // This key contains information about Skirting and Legs, both!
              millItem.options.skirting = SkirtingMill.fromV2(value)
              millItem.options.legs = LegMill.fromV2(value)
              break
            case 'handle':
              if (Object.keys(item.options).includes('drawers')) {
                // We semi-ignore this parsing since it's now an Appliance
                millItem.options.handleDrawer = HandleDrawerMill.fromV2()
              } else {
                millItem.options.handleDoors = HandleDoorMill.fromV2(value)
              }
              break
            case 'connection_width':
              millItem.options.hiddenVisibleWidth = HiddenVisibleWidthMill
                .fromV2(value, millItem.dimensions.x)
              break
            case 'extractor_adopted':
              millItem.options.fanAdoption = FanAdoptionMill.fromV2(value)
              break
            case 'combinedUnit':
              millItem.options.combinedUnit = CombinedUnitMill.fromV2(value)
              break
            case 'door_style':
              millItem.options.doorType = DoorTypeMill.fromV2(value)

              // Only if there are drawers:
              if (item.options['drawers']) {
                millItem.options.drawerFront = DrawerFrontMill.fromV2(value)
              }
              break
            case 'hinges':
              millItem.options.hinges = HingesMill.fromV2(value)
              break
            case 'shelves':
              millItem.options.shelves = ShelvesMill.fromV2(value)
              millItem.options.shelvesAdjustable = ShelvesAdjustableMill.fromV2(value)
              break
            case 'jar_spice_rack':
              millItem.options.spiceRack = SpiceRackMill
                .fromV2(value, millItem.code.includes('x2'))
              break
            case 'hanging':
              millItem.options.hanging = HangingMill.fromV2(value, false)
              break
            case 'lifting_hinge':
              millItem.options.hanging = HangingMill.fromV2(value, true)
              break
            case 'lighting':
              millItem.options.lighting = LightingMill.fromV2(value)
              break
            case 'cornice':
              millItem.options.cornice = CorniceMill.fromV2(value)
              break
            case 'middle_frame':
              millItem.options.centerPost = CenterPostMill.fromV2(value)
              break
            case 'paintProcess':
              millItem.options.paintProcess = PaintProcessMill.fromV2(value)
              break
            case 'console_right':
            case 'console_left':
              millItem.options.carpenterJoy = CarpenterJoyMill.fromV2(
                optionKey.includes('_left'),
                optionKey.includes('_right'),
                millItem.options.carpenterJoy)
              break
            case 'facades_type':
              millItem.options.doors = DoorMill.fromV2({
                hasDoor: true,
                isPaintedInside: item.options['painted_inside'] === 'Ja',
                doors: value
              })
              break
            case 'no_door':
              millItem.options.doors = DoorMill.fromV2({
                hasDoor: value,
                isPaintedInside: item.options['painted_inside'] === 'Ja',
                doors: {}
              })
              break
            case 'door_attachment':
              millItem.options.doorAttachment = DoorAttachmentMill.fromV2(value)
              break
            // case 'ramens bredd (admin only)':
            //   // TODO - Missing in JSON (but present in PB V2)
            //   millItem.options.scribings = ScribingsMill.fromV2(value)
            //   break
            case 'coverSide':
              millItem.options.coverSide = CoverSideMill.fromV2(value)
              break
            case 'backPanel':
              millItem.options.backPanel = BackPanelMill.fromV2(value)
              break
            case 'drawers':
              // Hidden drawer for sink, only if cabinet is a sink
              if (item.options['sink'] === true) {
                millItem.options.hiddenDrawerSink = HiddenDrawerSinkMill
                  .fromV2(value)
              }

              millItem.options.brassPlate = BrassPlateMill.fromV2(value)
              millItem.options.drawerInsert = DrawerInsertMill
                .fromV2(value, millItem.options.drawerInsert)
              break
            case 'filling':
              millItem.options.hiddenDrawer = HiddenDrawerMill
                .fromV2(value, millItem.options.hiddenDrawer)
              break
            case 'chopping_board':
              millItem.options.cuttingBoard = CuttingBoardMill.fromV2(value)
              break
            case 'paint_side_because_dw':
              millItem.options.paintSide = PaintSideMill.fromV2(value)
              break
            case 'front_side':
              millItem.options.fanExtractorPanel = FanExtractorPanelMill.fromV2(value)
              break
            default:
          }
        })

        // Special after-cases:
        // If cabinet has fanExtractorPanel option, meaning that it is a fan
        // extractor itself, we'll move all CoverSide options to it.
        if (millItem.options.fanExtractorPanel) {
          millItem.options.fanExtractorPanel.sides = [
            ...millItem.options.fanExtractorPanel.sides,
            ...millItem.options.coverSide.sides.map(s => ({
              side: s.side,
              style: FanExtractorPanelMill.getStyleFromCoverSideStyle(s.style),
              console: FanExtractorPanelMill.getConsoleFromCoverSideConsole(s.console)
            }))
          ]
          delete millItem.options.coverSide
        }

        return millItem
      })

    return {
      isMilf: true,
      prodboardUrl: jsonFile.prodboardUrl,
      prodboardId: jsonFile.prodboardId,
      prodboardNumber: jsonFile.prodboardNumber,
      customer: {
        name: jsonFile.customer?.name ?? '',
        email: jsonFile.customer?.email
      },
      plan: {
        items: millItems,
        room: {
          dimensions: jsonFile.plan.room.dimensions,
          // TODO - Include beams, which are those columns in the ceiling that
          //  will be used to calculate maximum cabinet height and display
          //  warnings if anything went wrong. Maybe not needed in V2?
          beams: []
        }
      }
    }
  }
}
